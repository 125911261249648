import React from "react";
import "assets/css/Limit.css";

function ReadyPage() {
  return (
    <div className="header-font">
      <i className="bi bi-chat-left-dots limit-icon-size"></i>
      <p className="limit-attend-font-size">준비중입니다.</p>
    </div>
  );
}

export default ReadyPage;
