import React, { useEffect, useState, useRef } from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useVerify } from "hooks/useVerify";
import { useAxios } from "hooks/useAxios";
import "assets/css/Admin.css";
import TextEditorModify from "components/TextEdiorModify";
import Spinner from "components/Spinner";

function AdminModifyNotice2() {
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const editorRef = useRef();
  const titleRef = useRef();
  const [loading, setLoading] = useState(false);
  const { verifyToken } = useVerify();
  const { accessAxios } = useAxios();
  const [modifyData, setModifyData] = useState({
    title: "",
    content: "",
  });

  const fetchingData = async () => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/get-detail-notice", {
        id: query.get("id"),
      });

      if (Object.keys(result.data[0]).length > 0) {
        setModifyData({
          ...modifyData,
          title: result.data[0]?.NOTICE_TITLE,
          content: result.data[0]?.NOTICE_CONTENT,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setModifyData({ title: "데이터 불러오기 실패" });
      }
    } catch (error) {
      setLoading(false);

      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const cancelModify = () => {
    navigate(
      `/admin-detail-notice2?page${query.get("page")}&id=${query.get("id")}`,
      { replace: true }
    );
  };

  const modifingData = async () => {
    const modifedData = {
      title: titleRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
      id: query.get("id"),
    };

    try {
      setLoading(true);
      const result = await accessAxios.post("/api/modify-notice", {
        ...modifedData,
      });

      if (result.data.resultCode === "00") {
        setLoading(false);
        Swal.fire({
          title: "수정완료",
          text: "공지사항 게시글이 수정되었습니다.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate(
          `/admin-detail-notice2?page=${query.get("page")}&id=${query.get(
            "id"
          )}`,
          { replace: true }
        );
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "수정하기 실패",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  useEffect(() => {
    fetchingData();
  }, []);

  useEffect(() => {
    editorRef.current.getInstance().setHTML(modifyData.content);
  });

  useEffect(() => {
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        const formData = new FormData();

        formData.append("image", blob);

        try {
          const result = await accessAxios.post("/api/image-upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          const imagePath = result.data.fileName;

          callback(
            process.env.REACT_APP_IMAGE_SERVER_URL + "/static/" + imagePath,
            "image"
          );
        } catch (error) {
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      });
    verifyToken();
  }, [editorRef]);

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <Helmet>
        <title>ycf관리자 - 공지사항 수정</title>
      </Helmet>
      <div className="d-flex">
        <AdminSideBar />
        <div className="container mt-5">
          <ExtraBold name="공지사항 글쓰기수정" />
          <div className="row">
            <div className="col">
              <TextEditorModify
                sendData={{
                  modifyData,
                  modifingData,
                  editorRef,
                  titleRef,
                }}
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-6 text-end">
              <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={modifingData}
              >
                수정완료
              </button>

              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={cancelModify}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminModifyNotice2;
