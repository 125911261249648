import React from "react";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";

function FrequentTextEditor(props) {
  const colorSyntaxOptions = {
    preset: [
      "#333333",
      "#666666",
      "#FFFFFF",
      "#EE2323",
      "#F89009",
      "#009A87",
      "#006DD7",
      "#8A3DB6",
      "#781B33",
      "#5733B1",
      "#953B34",
      "#FFC1C8",
      "#FFC9AF",
      "#9FEEC3",
      "#99CEFA",
      "#C1BEF9",
    ],
  };
  const toolbarItemsOptions = [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["image", "link"],
    ["code", "codeblock"],
  ];
  return (
    <>
      <div className="mb-1">
        <label htmlFor="title" className="col-sm-2 col-form-label bold-font">
          제목 <span className="essential">*</span>
        </label>
        <input
          type="text"
          id="title"
          className="form-control input-max-width"
          maxLength={100}
          ref={props.fn.titleRef}
        />
      </div>
      <Editor
        previewStyle="vertical"
        height="400px"
        initialEditType="wysiwyg"
        hideModeSwitch={true}
        toolbarItems={toolbarItemsOptions}
        plugins={[[colorSyntax, colorSyntaxOptions]]}
        ref={props.fn.editorRef}
      />
      <div className="container mt-2">
        <div className="row justify-content-end">
          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={props.fn.writeBoard}
            >
              작성완료
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={props.fn.cancelBoard}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FrequentTextEditor;
