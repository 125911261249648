import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useWriteNotice = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const titleRef = useRef();
  const editorRef = useRef();

  const { accessAxios } = useAxios();

  const errSweetAlert = (text) => {
    Swal.fire({
      title: `${text} 입력창`,
      text: `${text}을 써주세요.`,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const writeNotice = async () => {
    let pass = false;
    const writeBoard = {
      title: titleRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
    };

    if (writeBoard.title === "") {
      errSweetAlert("제목");
    } else if (writeBoard.content === "<p><br></p>") {
      errSweetAlert("내용");
    } else {
      pass = true;
    }

    if (pass) {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/write-notice", {
          ...writeBoard,
        });

        if (result.data.resultCode === "00") {
          setLoading(false);
          Swal.fire({
            title: `공지사항 글 작성완료`,
            text: `공지사항 게시글이 작성되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          navigate("/notice2", { replace: true });
        } else {
          setLoading(false);
          Swal.fire({
            title: "글쓰기를 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const cancelNotice = () => {
    if (
      titleRef.current.value === "" &&
      editorRef.current.getInstance().getHTML() === "<p><br></p>"
    ) {
      navigate(
        `/notice2?page=${query.get("page") === null ? 1 : query.get("page")}`
      );
    } else {
      Swal.fire({
        title: `글 작성 취소`,
        text: `글작성을 그만두고 나가시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 나가겠습니다..",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/notice2?page=${
              query.get("page") === null ? 1 : query.get("page")
            }`
          );
        }
      });
    }
  };

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 3;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `3MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        }
      });
  }, [editorRef]);

  return { writeNotice, titleRef, editorRef, loading, cancelNotice };
};
