import { useState, useEffect } from "react";
import { useAxios } from "hooks/useAxios";

export const useVerify = () => {
  const [isVerify, setIsVerify] = useState(false);
  const { instance } = useAxios();

  const isAccessToken = async () => {
    if (sessionStorage.getItem("loginData")) {
      if (
        JSON.parse(sessionStorage.getItem("loginData")).access_token &&
        JSON.parse(sessionStorage.getItem("loginData")).loginType === "local"
      ) {
        setIsVerify(true);
      }
    } else {
      setIsVerify(false);
    }
  };

  const verifyToken = async () => {
    try {
      await instance.post("/verify", {
        key: process.env.REACT_APP_CLIENT_SECRET_KEY,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isAccessToken();
  }, []);

  return { isVerify, verifyToken };
};
