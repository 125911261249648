import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "assets/css/Carousel.css";

function CarouselsReact() {
  return (
    <Carousel fade={true} wrap={true} controls={false} indicators={false}>
      <Carousel.Item interval={5000}>
        <img
          src={require("assets/img/carousel/main1.jpg")}
          className="d-block w-100 carousel-image-size"
          alt="main_img"
        />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          src={require("assets/img/carousel/main4.jpg")}
          className="d-block w-100 carousel-image-size"
          alt="main_img"
        />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          src={require("assets/img/carousel/main2.jpg")}
          className="d-block w-100 carousel-image-size"
          alt="main_img"
        />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          src={require("assets/img/carousel/main5.jpg")}
          className="d-block w-100 carousel-image-size"
          alt="main_img"
        />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          src={require("assets/img/carousel/main3.jpg")}
          className="d-block w-100 carousel-image-size"
          alt="main_img"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselsReact;
