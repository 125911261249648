import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";
import { useRefund } from "hooks/useRefund";
import Pagination2 from "components/Pagination2";

function Refund2() {
  const {
    tableMap,
    setPageNumberValue,
    pageNumberValue,
    loopCreatePageNumber,
    moveFirstPage,
    moveLastPage,
    boardData,
    loading,
    writeRefund,
    refundTableData,
  } = useRefund();

  if (refundTableData.length === 0)
    return (
      <>
        <Helmet>
          <title>ycf - 환불신청</title>
        </Helmet>
        <Header />
        <Bread first="환불신청" second={null} />
        <ExtraBold name="환불신청" />
        <div className="container">
          <div className="container text-center">
            <p>게시된 글이 없습니다.</p>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-end text-end">
            <div className="col-4">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  writeRefund();
                }}
              >
                글작성
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <div>
        <Helmet>
          <title>ycf - 환불신청</title>
        </Helmet>
        <Header />
        <Bread first="환불신청" second={null} />
        <ExtraBold name="환불신청" />
        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <p style={{ margin: "0" }}>
                환불 대상 : 7월 7일 이전 환불게시판 신청자{" "}
              </p>
              <p>환불 일정 : 7월 7일 이후 순차적 진행</p>
            </div>
          </div>
        </div>
        <div className="container">
          <table className="table main-font" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "50%" }}>
                  제목
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  작성자
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  작성일
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">{tableMap()}</tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Pagination2
              pageData={{
                pageNumberValue,
                setPageNumberValue,
                loopCreatePageNumber,
                moveFirstPage,
                moveLastPage,
                boardData,
              }}
            />
          </div>

          <div className="container">
            <div className="row justify-content-end text-end">
              <div className="col-4">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    writeRefund();
                  }}
                >
                  글작성
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Refund2;
