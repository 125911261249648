import { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router-dom";
import "assets/css/Table.css";

export const useMain = () => {
  const [popupData, setPopupData] = useState({});
  const [mainPageNoticeData, setMainPageNoticeData] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isWindowWidth, setIsWindowWidth] = useState(window.innerWidth < 768);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const createMainPageNoticeTable = () => {
    const createTable = mainPageNoticeData.map((data) => (
      <tr
        key={data.NOTICE_ID}
        onClick={() => {
          mainPageDetailNotice(data);
        }}
      >
        <td className="main-notice" style={{ textAlign: "left" }}>
          {isWindowWidth
            ? truncate(data.NOTICE_TITLE, 15)
            : truncate(data.NOTICE_TITLE, 25)}
        </td>
        <td>{data.NOTICE_CREATED_AT}</td>
      </tr>
    ));

    return createTable;
  };

  const mainPageDetailNotice = (data) => {
    navigate(`/detail-notice2?page=1&id=${data.NOTICE_ID}`);
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      setIsWindowWidth(window.innerWidth < 768);
    }
    const fetchingData = async () => {
      try {
        const result = await accessAxios.post("/api/read-popup");

        if (result.data) {
          if (!ignore) {
            setPopupData(result.data);
          }
          if (
            result.data.popupPeriod !== "" &&
            result.data.src !== "" &&
            result.data.originalFileName !== ""
          ) {
            if (!ignore) {
              setShowPopup(true);
            }
          } else {
            if (!ignore) {
              setShowPopup(false);
            }
          }
        }
        if (!ignore) {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (!ignore) {
          setLoading(false);
        }
        if (error.code === "ERR_BAD_RESPONSE") {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const mainPageNoticeFetchingData = async () => {
      try {
        const result = await accessAxios.post("/api/get-data-main-page-notice");

        if (result.data) {
          if (!ignore) {
            setMainPageNoticeData(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (error.code === "ERR_BAD_RESPONSE") {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();
    mainPageNoticeFetchingData();

    return () => {
      ignore = true;
    };
  }, []);

  return { popupData, showPopup, loading, createMainPageNoticeTable };
};
