import React from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { useParticipationList } from "hooks/useParticipationList";
import ListPaging from "components/ListPaging";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import "assets/css/Admin.css";
import Spinner from "components/Spinner";

function AdminIndividual() {
  const {
    listMap,
    setPageNumberValue,
    pageNumberValue,
    downloadExcelFileIndividualList,
    loading,
  } = useParticipationList("/read-individual-list");

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 개인참가신청 목록</title>
        </Helmet>
        <div className="container-fluid mt-5">
          <ExtraBold name="개인참가신청 목록" />
          <div className="row justify-content-end">
            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={downloadExcelFileIndividualList}
              >
                엑셀파일 다운로드
              </button>
            </div>
          </div>
          <table
            className="table main-font mt-3 admin-table"
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">이름</th>
                <th scope="col">전화번호</th>
                <th scope="col">성별</th>
                <th scope="col">주소</th>
                <th scope="col">상세주소</th>
                <th scope="col">코스종류</th>
                <th scope="col">구분</th>
                <th scope="col">신청일</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">{listMap()}</tbody>
          </table>
          <ListPaging
            serverUrl="/get-count-individual-list"
            fn={{
              setPageNumberValue,
              pageNumberValue,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminIndividual;
