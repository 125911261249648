import React, { useEffect, useState } from "react";
import AdminSideBar from "components/AdminSideBar";
import { useNavigate } from "react-router-dom";
import Accordion from "components/Accordion";
import ExtraBold from "components/ExtraBold";
import { useVerify } from "hooks/useVerify";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import { useAxios } from "hooks/useAxios";
import Spinner from "components/Spinner";
import "assets/css/Admin.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "assets/css/DetailBoard.css";

function AdminFrequent() {
  const [frequentData, setFrequentData] = useState([]);
  const [reRender, setReRender] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { accessAxios } = useAxios();
  const { isVerify } = useVerify();

  const writeFrequent = () => {
    navigate("/admin-write-frequent");
  };

  const accordionMap = (frequentData) => {
    const createAccordion = frequentData.map((data) => (
      <div className="accordion-item" key={data.FREQUENT_ID}>
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${data.FREQUENT_ID}`}
            aria-expanded="false"
            aria-controls={`collapse${data.FREQUENT_ID}`}
          >
            {data.FREQUENT_TITLE}
          </button>
        </h2>
        {isVerify ? (
          <>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                modifyFrequent(data);
              }}
            >
              수정
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                deleteFrequent(data);
              }}
            >
              삭제
            </button>
          </>
        ) : (
          <></>
        )}

        <div
          id={`collapse${data.FREQUENT_ID}`}
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div
            className="accordion-body img-size-adjust-frequent"
            dangerouslySetInnerHTML={{
              __html: data.FREQUENT_CONTENT,
            }}
          ></div>
        </div>
      </div>
    ));

    return createAccordion;
  };

  const modifyFrequent = (data) => {
    navigate("/admin-modify-frequent", { state: { id: data.FREQUENT_ID } });
  };

  const deleteFrequent = (data) => {
    Swal.fire({
      title: `자주하는 질문 게시글 삭제`,
      text: `자주하는 질문 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-frequent", {
            id: data.FREQUENT_ID,
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `자주하는 질문 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            setReRender(reRender + 1);
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        } catch (error) {
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/read-frequent");
        if (!ignore) {
          setLoading(false);
          setFrequentData(result.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };
    fetchingData();

    return () => {
      ignore = true;
    };
  }, [reRender]);
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 자주하는 질문</title>
        </Helmet>
        <div className="container mt-5">
          <div className="row">
            <div className="col">
              <ExtraBold name="자주하는 질문" />
              <Accordion fn={{ accordionMap, frequentData }} />
              <div className="mt-3"></div>

              <div className="container ">
                <div className="row justify-content-end text-end">
                  <div className="col-4">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        writeFrequent();
                      }}
                    >
                      글쓰기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminFrequent;
