import { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AOS from "aos";
import "aos/dist/aos.css";

export const useLastCompetition = () => {
  const { accessAxios } = useAxios();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const [nowYear, setNowYear] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const detailViewLastCompetition = (data) => {
    navigate(
      `/detail-last-competition?year=${nowYear}&id=${data.LAST_COMPETITION_ID}`
    );
  };

  const WriteLastCompetition = () => {
    navigate("/write-last-competition");
  };

  const createLi = () => {
    if (yearsData.length > 0) {
      const createMap = yearsData.map((data) => (
        <li
          key={data.LAST_COMPETITION_YEARS_ID}
          className="dropdown-item dropdown-custom"
          onClick={() => {
            lastCompetitionConditionList(data.LAST_COMPETITION_YEARS_ID);
          }}
        >
          {data.LAST_COMPETITION_YEARS_ID}
        </li>
      ));
      return createMap;
    } else {
      return (
        <li className="dropdown-item dropdown-custom">
          생성된 연도가 없습니다.
        </li>
      );
    }
  };

  const lastCompetitionConditionList = async (year) => {
    navigate(`/last-competition?year=${year}`);
    setNowYear(year);
    try {
      const readBoard = await accessAxios.post(
        "/api/read-board-last-competition",
        { year }
      );

      if (readBoard.data) {
        setList(readBoard.data);
      }
    } catch (error) {
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const createBoard = () => {
    const createMap = list.map((data) => (
      <div key={data.LAST_COMPETITION_ID}>
        <div
          className="card card-position "
          onClick={() => {
            detailViewLastCompetition(data);
          }}
          data-aos="fade-up"
        >
          <img
            src={
              process.env.REACT_APP_SUMNAIL_SERVER_URL +
              data.LAST_COMPETITION_SUMNAIL
            }
            className="card-img-top img-size"
            alt="competition_poster"
          />
        </div>
      </div>
    ));

    return createMap;
  };

  useEffect(() => {
    let ignor = false;

    const lastcompetitionYearData = async () => {
      try {
        if (!ignor) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-last-competition-year"
        );

        if (result.data.resultCode === "02") {
          if (!ignor) {
            setYearsData(result.data);
            setNowYear("생성된 연도가 없습니다.");
          }
        } else if (result.data.length > 0) {
          if (!ignor) {
            setYearsData(result.data);
            setNowYear(result.data[0].LAST_COMPETITION_YEARS_ID);
          }
        }

        if (result.data.length > 0) {
          const readBoard = await accessAxios.post(
            "/api/read-board-last-competition",
            { year: parseInt(result.data[0].LAST_COMPETITION_YEARS_ID) }
          );

          if (readBoard.data.resultCode === "02") {
            if (!ignor) {
              setList("작성된 글이 없습니다.");
            }
          } else if (readBoard.data.length > 0) {
            if (!ignor) {
              setList(readBoard.data);
            }
          }
        }
        if (!ignor) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const lastcompetitionYearDataGetUrl = async (year) => {
      try {
        if (!ignor) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-last-competition-year"
        );

        if (result.data.resultCode === "02") {
          if (!ignor) {
            setYearsData(result.data);
            setNowYear("생성된 연도가 없습니다.");
          }
        } else if (result.data.length > 0) {
          if (!ignor) {
            setYearsData(result.data);
            setNowYear(year);
          }
        }

        if (result.data.length > 0) {
          const readBoard = await accessAxios.post(
            "/api/read-board-last-competition",
            { year: parseInt(year) }
          );

          if (readBoard.data.resultCode === "02") {
            if (!ignor) {
              setList("작성된 글이 없습니다.");
            }
          } else if (readBoard.data.length > 0) {
            if (!ignor) {
              setList(readBoard.data);
            }
          }
        }
        if (!ignor) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    if (query.get("year") === null) {
      lastcompetitionYearData();
    } else {
      lastcompetitionYearDataGetUrl(query.get("year"));
    }

    AOS.init();

    return () => {
      ignor = true;
    };
  }, []);

  return {
    detailViewLastCompetition,
    WriteLastCompetition,
    nowYear,
    setNowYear,
    yearsData,
    createLi,
    loading,
    createBoard,
    list,
  };
};
