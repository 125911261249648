import { useState, useEffect, useRef } from "react";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useModifyLastCompetition = () => {
  const [selectedYear, setSelectedYear] = useState("연도선택");
  const [yearsData, setYearsData] = useState([]);
  const { accessAxios } = useAxios();
  const [sumnail, setSumnail] = useState("");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useSearchParams();
  const [detailLastCompetitionData, setDetailLastCompetitionData] = useState({
    title: "",
    content: "",
    sumnail: "",
  });
  const [isImage, setIsImage] = useState(false);
  const editorRef = useRef();
  const titleRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const errSweetAlert = (text) => {
    Swal.fire({
      title: `${text} 입력창`,
      text: `${text}을 써주세요.`,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const writePageCreateLi = () => {
    if (yearsData.length > 0) {
      const createMap = yearsData.map((data) => (
        <li
          key={data.LAST_COMPETITION_YEARS_ID}
          className="dropdown-item dropdown-custom"
          onClick={() => {
            setSelectedYear(data.LAST_COMPETITION_YEARS_ID);
          }}
        >
          {data.LAST_COMPETITION_YEARS_ID}
        </li>
      ));
      return createMap;
    } else {
      return (
        <li className="dropdown-item dropdown-custom">
          생성된 연도가 없습니다.
        </li>
      );
    }
  };

  const compliteBtn = async () => {
    if (isImage) {
      if (selectedYear === "연도선택") {
        Swal.fire({
          title: `연도선택`,
          text: `연도를 선택해주세요.`,
          icon: "error",
          showConfirmButton: true,
        });
      } else if (titleRef.current.value === "") {
        errSweetAlert("제목");
      } else if (editorRef.current?.getInstance().getHTML() === "<p><br></p>") {
        errSweetAlert("내용");
      } else if (sumnail === "") {
        Swal.fire({
          title: `썸네일 파일`,
          text: `썸네일 파일을 업로드해주세요.`,
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        const formData = new FormData();
        formData.append("sumnail", sumnail);
        formData.append("year", selectedYear);
        formData.append("title", titleRef.current.value);
        formData.append("content", editorRef.current?.getInstance().getHTML());
        formData.append("id", detailLastCompetitionData.id);
        formData.append("beforeYear", detailLastCompetitionData.year);

        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/update-image-change-board-last-competition",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setLoading(false);
          if (result.data.resultCode === "00") {
            navigate(
              `/detail-last-competition?year=${selectedYear}&id=${query.get(
                "id"
              )}`,
              { replace: true }
            );
            Swal.fire({
              title: "글 수정완료",
              text: "지난대회 게시글이 수정되었습니다.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              title: "글쓰기를 실패했습니다.",
              text: "관리자에게 문의해주세요.",
              icon: "error",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    } else {
      if (selectedYear === "연도선택") {
        Swal.fire({
          title: `연도선택`,
          text: `연도를 선택해주세요.`,
          icon: "error",
          showConfirmButton: true,
        });
      } else if (titleRef.current.value === "") {
        errSweetAlert("제목");
      } else if (editorRef.current?.getInstance().getHTML() === "<p><br></p>") {
        errSweetAlert("내용");
      } else {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/update-board-last-competition",
            {
              year: selectedYear,
              title: titleRef.current.value,
              content: editorRef.current?.getInstance().getHTML(),
              id: detailLastCompetitionData.id,
            }
          );
          setLoading(false);
          if (result.data.resultCode === "00") {
            navigate(
              `/detail-last-competition?year=${selectedYear}&id=${query.get(
                "id"
              )}`,
              { replace: true }
            );
            Swal.fire({
              title: "글 수정완료",
              text: "지난대회 게시글이 수정되었습니다.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              title: "글쓰기를 실패했습니다.",
              text: "관리자에게 문의해주세요.",
              icon: "error",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    }
  };

  const checkSumnailFile = (e) => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 1;

    const file = e.target.files[0];

    if (file) {
      if (limitVolume < file.size) {
        Swal.fire({
          title: "썸네일 허용용량 초과",
          text: "1MB 이하 이미지를 사용해주세요.",
          icon: "error",
          showConfirmButton: true,
        });
        e.target.value = "";
      } else if (!fileAccept.includes(file.type)) {
        Swal.fire({
          title: `파일 확장자`,
          text: `해당 파일 확장자는 업로드되지 않습니다.`,
          icon: "error",
          showConfirmButton: true,
        });
        e.target.value = "";
      } else {
        setSumnail(file);
        setIsImage(true);
      }
    }
  };

  const imagePathArr = [];

  const editorInputData = () => {
    const e = editorRef.current.getInstance().getHTML();

    sessionStorage.setItem("temporaryText", e);
  };

  const handleKeyDown = (e, ev) => {
    const content = editorRef.current.getInstance().getHTML();

    if (ev.key === "Backspace" && content.includes("<img src=")) {
      const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
      const matches = content.matchAll(regex);

      for (const match of matches) {
        const src = match[1].split("/").pop();
        if (!imagePathArr.includes(src)) {
          imagePathArr.push(src);
        }
      }
    }
  };

  const handleKeyUp = async (e, ev) => {
    const content = editorRef.current.getInstance().getHTML();

    if (ev.key === "Backspace" && imagePathArr.length > 0) {
      console.log("$#@$#$#");
      const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
      const matches = content.matchAll(regex);
      const srcArr = [];

      for (const match of matches) {
        const src = match[1].split("/").pop();

        srcArr.push(src);
      }

      const missStr = [];
      console.log(srcArr);
      for (const i of imagePathArr) {
        if (srcArr.indexOf(i) < 0) {
          imagePathArr.splice(imagePathArr.indexOf(i), 1);
          missStr.push(i);
        }
      }

      if (missStr.length > 0) {
        accessAxios.post("/api/delete-image", {
          deleteImageList: missStr,
        });
      }
    }
  };

  const unmountedDeleteImage = () => {
    let content = sessionStorage.getItem("temporaryText");
    if (content === null) {
      content = "";
    }
    const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
    const matches = content.matchAll(regex);
    const srcArr = [];

    for (const match of matches) {
      const src = match[1].split("/").pop();

      srcArr.push(src);
    }

    if (srcArr.length > 0) {
      accessAxios.post("/api/delete-image", {
        deleteImageList: srcArr,
      });
    }
  };

  const cancelModifyLastCompetition = () => {
    if (
      titleRef.current.value === detailLastCompetitionData.title &&
      editorRef.current.getInstance().getHTML() ===
        detailLastCompetitionData.content &&
      detailLastCompetitionData.year === selectedYear
    ) {
      navigate(
        `/detail-last-competition?year=${query.get("year")}&id=${query.get(
          "id"
        )}`
      );
    } else {
      Swal.fire({
        title: `글 수정 취소`,
        text: `글 수정을 그만두고 나가시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 나가겠습니다..",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/last-competition?year=${query.get("year")}&id=${query.get("id")}`
          );
        }
      });
    }
  };

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 1;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");

    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `1MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        }
      });
  }, [editorRef]);

  useEffect(() => {
    editorRef.current.getInstance().setHTML(detailLastCompetitionData.content);
  });

  useEffect(() => {
    let ignore = false;
    const LastcompetitionData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-last-competition-year"
        );

        if (result.data.resultCode === "02") {
          if (!ignore) {
            setSelectedYear("생성된 연도가 없습니다.");
            setYearsData(result.data);
            setLoading(false);
          }

          Swal.fire({
            title: "연도 생성",
            text: "관리자 페이지에서 연도를 생성해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        } else if (result.data.length > 0) {
          if (!ignore) {
            setYearsData(result.data);
            setLoading(false);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-detail-last-competition",
          {
            year: query.get("year"),
            id: query.get("id"),
          }
        );

        if (result.data.length > 0) {
          if (!ignore) {
            setDetailLastCompetitionData({
              ...detailLastCompetitionData,
              title: result.data[0].LAST_COMPETITION_TITLE,
              content: result.data[0].LAST_COMPETITION_CONTENT,
              sumnail: result.data[0].LAST_COMPETITION_SUMNAIL,
              year: result.data[0].LAST_COMPETITION_YEARS_ID_FK,
              id: result.data[0].LAST_COMPETITION_ID,
            });
            setSelectedYear(result.data[0].LAST_COMPETITION_YEARS_ID_FK);
            setLoading(false);
          }
        } else {
          if (!ignore) {
            setLoading(false);
            setDetailLastCompetitionData({ title: "데이터 불러오기 실패" });
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }

        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();
    LastcompetitionData();

    const beforeUnloadListener = (event) => {
      if (event.returnValue === "") {
        unmountedDeleteImage();
      }
    };

    window.addEventListener("beforeunload", beforeUnloadListener);

    return () => {
      ignore = true;
      sessionStorage.removeItem("temporaryText");
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 1;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        console.log(blob.size);
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `1MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        }
      });
  }, [editorRef]);

  useEffect(() => {
    const preventGoBack = () => {
      window.history.pushState(null, "", location.href);

      if (
        titleRef.current.value === "" &&
        editorRef.current.getInstance().getHTML() === "<p><br></p>"
      ) {
        navigate(
          `/detail-last-competition?year=${query.get("year")}&id=${query.get(
            "id"
          )}`
        );
      } else {
        Swal.fire({
          title: `글 수정 취소`,
          text: `글 수정을 그만두고 나가시겠습니까?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "네, 나가겠습니다..",
          cancelButtonText: "아니오",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate(
              `/last-competition?year=${query.get("year")}&id=${query.get(
                "id"
              )}`
            );
          }
        });
      }
    };

    window.history.pushState(null, "", location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => window.removeEventListener("popstate", preventGoBack);
  }, []);

  return {
    loading,
    selectedYear,
    writePageCreateLi,
    titleRef,
    editorRef,
    editorInputData,
    detailLastCompetitionData,
    handleKeyDown,
    handleKeyUp,
    checkSumnailFile,
    compliteBtn,
    cancelModifyLastCompetition,
  };
};
