import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./assets/css/Global.css";
import "./assets/css/Font.css";

import Main from "views/Main";

/* 연맹소개 */
import Salutation from "views/ycfInfo/Salutation";
import History from "views/ycfInfo/History";
import YcfInfo from "views/ycfInfo/YcfInfo";
import Organization from "views/ycfInfo/Organization";

/* 대회정보 */
import Info from "views/infoMenu/Info";
import Souvenir from "views/infoMenu/Souvenir";
import Course from "views/infoMenu/Course";
import Award from "views/infoMenu/Award";
import SponsorPage from "views/infoMenu/SponsorPage";
import Picture from "views/infoMenu/Picture";

/* 지난대회 */
import LastCompetition from "views/lastCompetition/LastCompetition";
import WriteLastCompetition from "views/lastCompetition/WriteLastCompetition";
import ModifyLastCompetition from "views/lastCompetition/ModifyLastCompetition";
import DetailLastCompetition from "views/lastCompetition/DetailLastCompetition";

/* 참가신청 */
import Individual from "views/participationMenu/Individual";
import IndividualComplet from "views/participationMenu/IndividualComplet";
import IndividualInquiry from "views/participationMenu/IndividualInquiry";
import IndividualInquiryComplet from "views/participationMenu/IndividualInquiryComplet";

import Group from "views/participationMenu/Group";
import GroupComplet from "views/participationMenu/GroupComplet";
import GroupInquiry from "views/participationMenu/GroupInquiry";
import GroupInquiryCompet from "views/participationMenu/GroupInquiryComplet";

/* 대획기록 */
import Record from "views/Record";

/* 커뮤니티 */

import Frequent from "views/communityMenu/Frequent";
import WriteFrequent from "views/communityMenu/WriteFrequent";
import ModifyFrequent from "views/communityMenu/ModifyFrequent";

/* 다시만드는 커뮤니티*/
import Notice2 from "views/comunity2Menu/Notice2";
import Question2 from "views/comunity2Menu/Question2";
import Refund2 from "views/comunity2Menu/Refund2";
import DetailNotice2 from "views/comunity2Menu/DetailNotice2";
import DetailQuestion2 from "views/comunity2Menu/DetailQuestion2";
import DetailRefund2 from "views/comunity2Menu/DetailRefund2";
import WriteNotice2 from "views/comunity2Menu/WriteNotice2";
import ModifyNotice2 from "views/comunity2Menu/ModifyNotice2";
import WriteQuestion2 from "views/comunity2Menu/WriteQuestion2";
import WriteRefund2 from "views/comunity2Menu/WriteRefund2";

/* 로그인 */
import Login from "views/Login";

/* 관리자 페이지*/
import Admin from "views/admin/Admin";
import AdminIndividual from "views/admin/AdminIndividual";
import AdminGroup from "views/admin/AdminGroup";
import AdminTotalParticipation from "views/admin/AdminTotalParticipation";

import AdminFrequent from "views/admin/AdminFrequent";

import AdminSetting from "views/admin/AdminSetting";

import AdminWriteFrequent from "views/admin/AdminWriteFrequent";

import AdminAccountSetting from "views/admin/AdminAccountSetting";
import AdminModifyFrequent from "views/admin/AdminModifyFrequent";

// 다시만드는 관리자 페이지

import AdminNotice2 from "views/admin2/AdminNotice2";
import AdminQuestion2 from "views/admin2/AdminQuestion2";
import AdminRefund2 from "views/admin2/AdminRefund2";
import AdminDetailNotice2 from "views/admin2/AdminDetailNotice2";
import AdminDetailQuestion2 from "views/admin2/AdminDetailQuestion2";
import AdminDetailRefund2 from "views/admin2/AdminDetailRefund2";
import AdminModifyNotice2 from "views/admin2/AdminModifyNotice2";
import AdminWriteNotice2 from "views/admin2/AdminWriteNotice2";

//소셜 처리
import SocialProcess from "views/SocialProcess";

import PrivateRoute from "components/PrivateRoute";
import ProtectedRoute from "components/ProtectedRoute";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/ycf-salutaion" element={<Salutation />} />
          <Route path="/ycf-history" element={<History />} />
          <Route path="/ycf-info" element={<YcfInfo />} />
          <Route path="/ycf-organization" element={<Organization />} />
          <Route path="/info" element={<Info />} />
          <Route path="/course" element={<Course />} />
          <Route path="/souvenir" element={<Souvenir />} />
          <Route path="/picture" element={<Picture />} />
          <Route path="/sponsor-page" element={<SponsorPage />} />
          <Route path="/award" element={<Award />} />
          <Route path="/last-competition/*" element={<LastCompetition />} />
          <Route
            path="/write-last-competition"
            element={<WriteLastCompetition />}
          />
          <Route
            path="/modify-last-competition/*"
            element={<ModifyLastCompetition />}
          />
          <Route
            path="/detail-last-competition/*"
            element={<DetailLastCompetition />}
          />
          <Route path="/individual" element={<Individual />} />
          <Route path="/individual-inquiry" element={<IndividualInquiry />} />
          <Route
            path="/individual-inquiry-complet"
            element={<IndividualInquiryComplet />}
          />
          <Route path="/individual-complet" element={<IndividualComplet />} />
          <Route path="/group" element={<Group />} />
          <Route path="/group-complet" element={<GroupComplet />} />
          <Route path="/group-inquiry" element={<GroupInquiry />} />
          <Route
            path="/group-inquiry-complet"
            element={<GroupInquiryCompet />}
          />
          <Route path="/record" element={<Record />} />
          <Route path="/frequent" element={<Frequent />} />
          <Route path="/notice2/*" element={<Notice2 />} />
          <Route path="/question2/*" element={<Question2 />} />
          <Route path="/refund2/*" element={<Refund2 />} />
          <Route path="/detail-notice2/*" element={<DetailNotice2 />} />
          <Route path="/detail-question2/*" element={<DetailQuestion2 />} />
          <Route path="/detail-refund2/*" element={<DetailRefund2 />} />
          <Route path="/write-question2/*" element={<WriteQuestion2 />} />
          <Route path="/write-refund2/*" element={<WriteRefund2 />} />
          <Route path="/social" element={<SocialProcess />} />

          <Route element={<PrivateRoute />}>
            {/* 다시만드는중 START */}
            <Route path="/admin-notice2/*" element={<AdminNotice2 />} />
            <Route
              path="/admin-write-notice2/*"
              element={<AdminWriteNotice2 />}
            />
            <Route
              path="/admin-detail-notice2/*"
              element={<AdminDetailNotice2 />}
            />
            <Route path="/admin-question2/*" element={<AdminQuestion2 />} />
            <Route
              path="/admin-detail-question2/*"
              element={<AdminDetailQuestion2 />}
            />
            <Route path="/admin-refund2/*" element={<AdminRefund2 />} />
            <Route
              path="/admin-detail-refund2/*"
              element={<AdminDetailRefund2 />}
            />
            <Route
              path="/admin-modify-notice2/*"
              element={<AdminModifyNotice2 />}
            />
            <Route
              path="/admin-modify-frequent/*"
              element={<AdminModifyFrequent />}
            />
            {/* 다시만드는중 END */}

            <Route path="/admin" element={<Admin />} />
            <Route path="/write-notice2/*" element={<WriteNotice2 />} />
            <Route path="/modify-notice2/*" element={<ModifyNotice2 />} />

            <Route path="/admin-individual" element={<AdminIndividual />} />
            <Route path="/admin-group" element={<AdminGroup />} />
            <Route
              path="/admin-total-participation"
              element={<AdminTotalParticipation />}
            />
            <Route path="/admin-frequent" element={<AdminFrequent />} />

            <Route path="/admin-setting" element={<AdminSetting />} />
            <Route
              path="/admin-write-frequent"
              element={<AdminWriteFrequent />}
            />
            <Route
              path="/admin-account-setting"
              element={<AdminAccountSetting />}
            />
            <Route path="/modify-frequent" element={<ModifyFrequent />} />
            <Route path="/write-frequent" element={<WriteFrequent />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
