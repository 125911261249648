import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";
import { useDetailLastCompetition } from "hooks/useDetailLastCompetition";
import "assets/css/DetailLastCompetition.css";

function DetailLastCompetition() {
  const {
    detailLastCompetitionData,
    loading,
    isVerify,
    deleteBoard,
    moveList,
    moveModify,
  } = useDetailLastCompetition();

  return (
    <div>
      <Helmet>
        <title>ycf - 지난대회 상세보기</title>
      </Helmet>
      <Header />
      <Bread first="지난대회" second={null} />
      <ExtraBold name="지난대회 상세보기" />
      {loading ? <Spinner /> : <></>}
      <div className="container">
        <div className="row justify-content-end text-end bold-font">
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={moveList}
            >
              목록
            </button>
            {isVerify ? (
              <>
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={moveModify}
                >
                  수정
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={deleteBoard}
                >
                  삭제
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row extar-bold-font text-center detail-last-competition-title">
          <div className="col">
            {detailLastCompetitionData[0]?.LAST_COMPETITION_TITLE}
          </div>
        </div>
        <div className="row text-end bold-font detail-last-competition-created">
          <div className="col">
            {detailLastCompetitionData[0]?.LAST_COMPETITION_CREATED_AT}
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <div className="col-md-8">
            <img
              src={
                process.env.REACT_APP_SUMNAIL_SERVER_URL +
                detailLastCompetitionData[0]?.LAST_COMPETITION_SUMNAIL
              }
              alt="sumnail"
              className="detail-last-competition-image"
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div
            className="col detail-last-competition-content img-size-adjust"
            dangerouslySetInnerHTML={{
              __html: detailLastCompetitionData[0]?.LAST_COMPETITION_CONTENT,
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailLastCompetition;
