import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import Accordion from "components/Accordion";
import { Helmet } from "react-helmet";
import { useFrequent } from "hooks/useFrequent";
import Spinner from "components/Spinner";
import "assets/css/DetailBoard.css";

function Frequent() {
  const { writeFrequent, accordionMap, frequentData, isVerify, loading } =
    useFrequent();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 자주하는 질문</title>
      </Helmet>
      <Header />
      <Bread first="자주하는 질문" second={null} />
      <ExtraBold name="자주하는 질문" />
      <div className="container main-font">
        <div className="row">
          <div className="col img-size-adjust-frequent">
            <Accordion fn={{ accordionMap, frequentData }} />
            <div className="mt-3"></div>
            {isVerify ? (
              <>
                <div className="container">
                  <div className="row justify-content-end text-end">
                    <div className="col-4">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                          writeFrequent();
                        }}
                      >
                        글쓰기
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Frequent;
