import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import TextEditor from "components/TextEditor";
import Spinner from "components/Spinner";
import { Helmet } from "react-helmet";
import { useWriteNotice } from "hooks/useWriteNotice";

function WriteNotice2() {
  const { writeNotice, titleRef, editorRef, loading, cancelNotice } =
    useWriteNotice();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 공지사항 글쓰기</title>
      </Helmet>
      <Header />
      <Bread first="공지사항" second={null} />
      <ExtraBold name="공지사항 글쓰기" />
      <div className="container">
        <div className="row">
          <div className="col">
            <TextEditor hooksFunction={{ titleRef, editorRef }} />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-end">
          <div className="col-3 text-end">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={writeNotice}
            >
              작성완료
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={cancelNotice}
            >
              취소
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WriteNotice2;
