import { useState, useEffect } from "react";
import { useVerify } from "hooks/useVerify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";

export const useNotice = () => {
  const [noticeTableData, setNoticeTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWindowWidth, setIsWindowWidth] = useState(window.innerWidth < 768);
  const { isVerify } = useVerify();
  const { accessAxios } = useAxios();
  const [query, setQuery] = useSearchParams();
  const [pageNumberValue, setPageNumberValue] = useState(
    query.get("page") || 1
  );
  const [boardData, setBoardData] = useState([]);
  const navigate = useNavigate();

  if (query.get("page") === null && pageNumberValue != 1) {
    setPageNumberValue(1);
  }
  if (pageNumberValue != query.get("page") && query.get("page") !== null) {
    setPageNumberValue(query.get("page"));
  }

  const truncateString = 10;

  const loopCreatePageNumber = () => {
    const totalPageNumber = boardData.totalPageNumber;

    let startNumber = Math.floor((pageNumberValue - 1) / 5);
    let endNumber = (startNumber + 1) * 5;

    if (totalPageNumber < endNumber) {
      endNumber = totalPageNumber;
    }

    const lenderingPageNumber = [];
    for (let i = startNumber * 5; i < endNumber; i++) {
      lenderingPageNumber.push(
        <Pagination.Item
          key={i}
          active={i === pageNumberValue - 1}
          onClick={() => {
            setPageNumberValue(i + 1);
            navigate(`/notice2?page=${i + 1}`);
          }}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    return lenderingPageNumber;
  };

  const moveFirstPage = () => {
    setPageNumberValue(1);
    navigate(`/notice2?page=${1}`);
  };

  const moveLastPage = () => {
    setPageNumberValue(boardData.totalPageNumber);
    navigate(`/notice2?page=${boardData.totalPageNumber}`);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const handleDetail = async (data) => {
    navigate(`/detail-notice2?page=${pageNumberValue}&id=${data.NOTICE_ID}`);
  };

  const tableMap = () => {
    const createTable = noticeTableData.map((data) => (
      <tr className="row-background-color" key={data.NOTICE_ID}>
        <td
          className="title-click"
          onClick={() => {
            handleDetail(data);
          }}
          style={{ textAlign: "left" }}
        >
          &nbsp;
          {isWindowWidth
            ? truncate(data.NOTICE_TITLE, truncateString)
            : data.NOTICE_TITLE}
        </td>
        <td>{data.NOTICE_CREATED_AT}</td>
      </tr>
    ));

    return createTable;
  };

  const writeNotice = () => {
    navigate(
      `/write-notice2?page=${
        query.get("page") === null ? 1 : query.get("page")
      }`
    );
  };

  useEffect(() => {
    setIsWindowWidth(window.innerWidth < 768);
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }

        let pageNum = pageNumberValue;
        if (query.get("page")) {
          pageNum = query.get("page");
        }

        const result = await accessAxios.post("/api/get-data-notice", {
          pageNumberValue: pageNum,
        });
        if (!ignore) {
          setNoticeTableData(result.data);
        }
        if (!ignore) {
          setLoading(false);
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const getCount = async () => {
      try {
        const result = await accessAxios.post("/api/get-notice-count");

        setBoardData(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchingData();
    getCount();

    return () => {
      ignore = true;
    };
  }, [pageNumberValue]);

  return {
    tableMap,
    isVerify,
    setPageNumberValue,
    pageNumberValue,
    loopCreatePageNumber,
    moveFirstPage,
    moveLastPage,
    boardData,
    loading,
    writeNotice,
    noticeTableData,
  };
};
