import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useInquiry = (type) => {
  const navigate = useNavigate();
  const [inquiryData, setInquiryData] = useState({
    name: "",
    phoneNumber: "",
  });
  const [isValid, setIsValid] = useState({
    name: false,
    phoneNumber: false,
  });
  const nameRef = useRef();
  const phoneNumberRef = useRef();

  const participationApplicationInquiry = async () => {
    if (inquiryData.name === "") {
      setIsValid({ ...isValid, name: true });
      nameRef.current.focus();
    } else if (inquiryData.phoneNumber === "") {
      setIsValid({ ...isValid, phoneNumber: true });
      phoneNumberRef.current.focus();
    } else {
      if (type === "individual") {
        navigate("/individual-inquiry-complet", {
          state: {
            name: inquiryData.name,
            phoneNumber: inquiryData.phoneNumber,
          },
        });
      } else if (type === "group") {
        navigate("/group-inquiry-complet", {
          state: {
            name: inquiryData.name,
            phoneNumber: inquiryData.phoneNumber,
          },
        });
      }
    }
  };

  const getData = (e) => {
    if (e.target.id === "name") {
      setInquiryData({ ...inquiryData, name: e.target.value });
      if (isValid.name === true) {
        setIsValid({ ...isValid, name: false });
      }
    } else if (e.target.id === "phoneNumber") {
      setInquiryData({ ...inquiryData, phoneNumber: e.target.value });
      if (isValid.phoneNumber === true) {
        setIsValid({ ...isValid, phoneNumber: false });
      }
    }
  };

  useEffect(() => {}, [inquiryData]);

  return {
    participationApplicationInquiry,
    inquiryData,
    getData,
    nameRef,
    phoneNumberRef,
    isValid,
  };
};
