import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { useListPaging } from "hooks/useListPaging";

function ListPaging(props) {
  const { boardData, loopCreatePageNumber, moveFirstPage, moveLastPage } =
    useListPaging(props.serverUrl, props);

  return (
    <div className="d-flex justify-content-center">
      <Pagination>
        <Pagination.First onClick={moveFirstPage} />
        <Pagination.Prev
          onClick={() => {
            if (props.fn.pageNumberValue > 1) {
              props.fn.setPageNumberValue(props.fn.pageNumberValue - 1);
            }
          }}
        />
        {loopCreatePageNumber()}
        <Pagination.Next
          onClick={() => {
            if (props.fn.pageNumberValue < boardData.totalPageNumber) {
              props.fn.setPageNumberValue(props.fn.pageNumberValue + 1);
            }
          }}
        />
        <Pagination.Last onClick={moveLastPage} />
      </Pagination>
    </div>
  );
}

export default ListPaging;
