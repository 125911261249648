import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import "../assets/css/Global.css";
function Bread(props) {
  return (
    <div>
      <div className="container mt-5 bread-crumb">
        <Breadcrumb listTag="div">
          <BreadcrumbItem active tag="a">
            Home
          </BreadcrumbItem>
          <BreadcrumbItem active tag="a">
            {props.first}
          </BreadcrumbItem>
          {props.second ? (
            <BreadcrumbItem active tag="a">
              {props.second}
            </BreadcrumbItem>
          ) : null}
        </Breadcrumb>
      </div>
    </div>
  );
}

export default Bread;
