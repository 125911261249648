import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";
import Pagination2 from "components/Pagination2";
import { useNotice } from "hooks/useNotice";

function Notice2() {
  const {
    tableMap,
    isVerify,
    setPageNumberValue,
    pageNumberValue,
    loopCreatePageNumber,
    moveFirstPage,
    moveLastPage,
    boardData,
    loading,
    noticeTableData,
    writeNotice,
  } = useNotice();

  if (noticeTableData.length === 0)
    return (
      <>
        {loading ? <Spinner /> : <></>}
        <Helmet>
          <title>ycf - 공지사항</title>
        </Helmet>
        <Header />
        <Bread first="공지사항" second={null} />
        <ExtraBold name="공지사항" />
        <div className="container">
          <div className="row text-center">
            <p>게시된 글이 없습니다.</p>
          </div>
        </div>
        {isVerify ? (
          <>
            <div className="container">
              <div className="row justify-content-end text-end">
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      writeNotice();
                    }}
                  >
                    글작성
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <Footer />
      </>
    );
  return (
    <div>
      <Helmet>
        <title>ycf - 공지사항</title>
      </Helmet>
      {loading ? <Spinner /> : <></>}
      <Header />
      <Bread first="공지사항" second={null} />
      <ExtraBold name="공지사항" />
      <div className="container">
        <table className="table main-font" style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th scope="col" style={{ width: "70%" }}>
                제목
              </th>
              <th scope="col" style={{ width: "30%" }}>
                작성일
              </th>
            </tr>
          </thead>
          <tbody className="table-group-divider">{tableMap()}</tbody>
        </table>
        <div className="d-flex justify-content-center">
          <Pagination2
            pageData={{
              pageNumberValue,
              setPageNumberValue,
              loopCreatePageNumber,
              moveFirstPage,
              moveLastPage,
              boardData,
            }}
            boardType="/notice2"
          />
        </div>
        {isVerify ? (
          <>
            <div className="container">
              <div className="row justify-content-end text-end">
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      writeNotice();
                    }}
                  >
                    글작성
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Notice2;
