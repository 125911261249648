import React from "react";

function ImageUpLoad(props) {
  return (
    <div>
      <div className="row">
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">
            변경할 썸네일 이미지 업로드
          </label>
          <input
            className="form-control"
            type="file"
            id="formFile"
            accept=".jpeg, .png, .bmp, .gif, .webp, .svg"
            onChange={props.fn.checkSumnailFile}
          />
          <span className="main-font" style={{ fontSize: "12px" }}>
            용량 1MB 이하, 업로드 가능 확장자 : .jpg .png .bmp .gif .webp .svg
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <p className="bold-font" style={{ margin: 0 }}>
            현재 이미지
          </p>
          <img
            src={`${
              process.env.REACT_APP_SUMNAIL_SERVER_URL +
              props.fn.detailLastCompetitionData.sumnail
            }`}
            alt="sumnail"
            width={100}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUpLoad;
