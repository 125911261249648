import React from "react";
import CompletPage from "components/CompletPage";
import { Helmet } from "react-helmet";

function IndividualComplet() {
  return (
    <div>
      <Helmet>
        <title>ycf - 개인참가신청조회</title>
      </Helmet>
      <div>
        <CompletPage moveUrl="/individual-inquiry" msg="개인참가신청" />
      </div>
    </div>
  );
}

export default IndividualComplet;
