import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Pagination from "react-bootstrap/Pagination";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { useVerify } from "hooks/useVerify";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import "assets/css/Admin.css";
import Spinner from "components/Spinner";
import Pagination2 from "components/Pagination2";

function AdminQuestion2() {
  const [questionTableData, setQuestionTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWindowWidth, setIsWindowWidth] = useState(window.innerWidth < 768);
  const { isVerify } = useVerify();
  const { accessAxios } = useAxios();
  const [query, setQuery] = useSearchParams();
  const [pageNumberValue, setPageNumberValue] = useState(1);
  const [boardData, setBoardData] = useState([]);
  const navigate = useNavigate();

  if (query.get("page") === null && pageNumberValue != 1) {
    setPageNumberValue(1);
  }
  if (pageNumberValue != query.get("page") && query.get("page") !== null) {
    setPageNumberValue(query.get("page"));
  }

  const truncateString = 10;

  const loopCreatePageNumber = () => {
    const totalPageNumber = boardData.totalPageNumber;

    let startNumber = Math.floor((pageNumberValue - 1) / 5);
    let endNumber = (startNumber + 1) * 5;

    if (totalPageNumber < endNumber) {
      endNumber = totalPageNumber;
    }

    const lenderingPageNumber = [];
    for (let i = startNumber * 5; i < endNumber; i++) {
      lenderingPageNumber.push(
        <Pagination.Item
          key={i}
          active={i === pageNumberValue - 1}
          onClick={() => {
            setPageNumberValue(i + 1);
            navigate(`/admin-question2?page=${i + 1}`);
          }}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    return lenderingPageNumber;
  };

  const moveFirstPage = () => {
    setPageNumberValue(1);
    navigate(`/admin-question2?page=${1}`);
  };

  const moveLastPage = () => {
    setPageNumberValue(boardData.totalPageNumber);
    navigate(`/admin-question2?page=${boardData.totalPageNumber}`);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const fetchingPwData = async (data, inputValue) => {
    let isValid = false;

    try {
      setLoading(true);
      const result = await accessAxios.post("/api/check-question-password", {
        id: data.QUESTION_ID,
        pw: inputValue,
      });

      if (result.data.resultCode === "00") {
        isValid = true;
      }
      setLoading(false);
      return isValid;
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const handleDetail = async (data) => {
    if (isVerify) {
      navigate(
        `/admin-detail-question2?page=${pageNumberValue}&id=${data.QUESTION_ID}`
      );
    } else {
      const { value: password } = await Swal.fire({
        title: "비밀번호를 입력해주세요.",
        input: "password",
        inputLabel: "비밀번호를 입력해야 게시물을 보실 수 있습니다.",
        inputPlaceholder: "비밀번호를 입력해주세요.",
        inputAttributes: {
          autoComplet: "off",
          maxlength: "10",
          autocapitalize: "off",
          autocorrect: "off",
        },
      });
      setLoading(true);
      const checkPassword = await fetchingPwData(data, password);
      setLoading(false);
      if (checkPassword) {
        navigate(
          `/admin-detail-question2?page=${pageNumberValue}&id=${data.QUESTION_ID}`
        );
        Swal.fire({
          title: "인증성공",
          text: "비밀번호가 일치합니다.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "인증실패",
          text: "비밀번호가 다릅니다.",
          icon: "error",
          showConfirmButton: true,
        });
      }
    }
  };

  const tableMap = () => {
    const createTable = questionTableData.map((data) => (
      <tr className="row-background-color" key={data.QUESTION_ID}>
        <td
          className="title-click"
          onClick={() => {
            handleDetail(data);
          }}
          style={{ textAlign: "left" }}
        >
          {data.QUESTION_STATUS === "wait" ? (
            <span
              style={{
                fontFamily: "NanumGothicBold",
                fontWeight: "bold",
                display: "inline-block",
                width: "55px",
                height: "20px",
                fontSize: "12px",
                border: "#808080 1px solid",
                color: "#808080",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              답변대기
            </span>
          ) : data.QUESTION_STATUS === "ing" ? (
            <span
              style={{
                fontFamily: "NanumGothicBold",
                fontWeight: "bold",
                display: "inline-block",
                width: "55px",
                height: "20px",
                fontSize: "12px",
                border: "#FFC648 1px solid",
                color: "#FFC648",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              답변중
            </span>
          ) : (
            <span
              style={{
                fontFamily: "NanumGothicBold",
                fontWeight: "bold",
                display: "inline-block",
                width: "55px",
                height: "20px",
                fontSize: "12px",
                border: "#36bdef 1px solid",
                color: "#36bdef",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              답변완료
            </span>
          )}
          <i className="bi bi-lock-fill"></i>
          &nbsp;
          {isWindowWidth
            ? truncate(data.QUESTION_TITLE, truncateString)
            : data.QUESTION_TITLE}
        </td>
        <td className="title-click">{data.QUESTION_WRITER}</td>
        <td>{data.QUESTION_CREATED_AT}</td>
      </tr>
    ));

    return createTable;
  };

  const writeQuestion = () => {
    navigate(
      `/admin-write-question2?page=${
        query.get("page") === null ? 1 : query.get("page")
      }`
    );
  };

  useEffect(() => {
    setIsWindowWidth(window.innerWidth < 768);
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }

        let pageNum = pageNumberValue;
        if (query.get("page")) {
          pageNum = query.get("page");
        }

        const result = await accessAxios.post("/api/get-data-question", {
          pageNumberValue: pageNum,
        });
        if (!ignore) {
          setQuestionTableData(result.data);
        }
        if (!ignore) {
          setLoading(false);
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const getCount = async () => {
      try {
        const result = await accessAxios.post("/api/get-question-count");

        if (!ignore) {
          setBoardData(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchingData();
    getCount();

    return () => {
      ignore = true;
    };
  }, [pageNumberValue]);

  if (questionTableData.length === 0)
    return (
      <>
        {loading ? <Spinner /> : <></>}
        <MobileNavbar />
        <div className="d-flex">
          <AdminSideBar />
          <Helmet>
            <title>ycf관리자 - 문의사항</title>
          </Helmet>
          <div className="container mt-5">
            <ExtraBold name="문의사항" />
            <div className="row text-center">
              <p>게시된 글이 없습니다.</p>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 문의사항</title>
        </Helmet>
        <div className="container mt-5">
          <ExtraBold name="문의사항" />
          <table className="table main-font" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "50%" }}>
                  제목
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  작성자
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  작성일
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">{tableMap()}</tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Pagination2
              pageData={{
                pageNumberValue,
                setPageNumberValue,
                loopCreatePageNumber,
                moveFirstPage,
                moveLastPage,
                boardData,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminQuestion2;
