import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useVerify } from "hooks/useVerify";
import { useNavigate, useSearchParams } from "react-router-dom";
import "assets/css/DetailBoard.css";
import Spinner from "components/Spinner";

function DetailNotice2() {
  const [detailNoticeData, setDetailNoticeData] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { isVerify } = useVerify();

  useEffect(() => {
    let igonre = false;
    const fetchingData = async () => {
      const id = query.get("id");
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-detail-notice", {
          id,
        });
        setDetailNoticeData(result.data);
        if (!igonre) {
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();
    return () => {
      igonre = true;
    };
  }, []);

  const moveList = () => {
    navigate(`/notice2?page=${query.get("page")}`);
  };

  const moveModify = () => {
    navigate(`/modify-notice2?page=${query.get("page")}&id=${query.get("id")}`);
  };

  const deleteNotice = () => {
    Swal.fire({
      title: `공지사항 게시글 삭제`,
      text: `공지사항 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-notice", {
            id: query.get("id"),
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `공지사항 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(`/notice2?page=${query.get("page")}`);
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 공지사항 상세보기</title>
      </Helmet>
      <Header />
      <Bread first="공지사항" second={null} />
      <ExtraBold name="공지사항" />
      <div className="container mt-5">
        <div className="row extar-bold-font detail-board-title">
          <div className="col">{detailNoticeData[0]?.NOTICE_TITLE}</div>
        </div>
        <div className="row text-end bold-font detail-writer">
          <div className="col">
            {detailNoticeData[0]?.NOTICE_WRITER}
            &nbsp;&nbsp;
            {detailNoticeData[0]?.NOTICE_CREATED_AT}
          </div>
        </div>
        <br />
        <div className="row">
          <div
            className="col img-size-adjust"
            dangerouslySetInnerHTML={{
              __html: detailNoticeData[0]?.NOTICE_CONTENT,
            }}
          ></div>
        </div>
        <br />
        <div className="row justify-content-end text-end bold-font">
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={moveList}
            >
              목록
            </button>
            {isVerify ? (
              <>
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={moveModify}
                >
                  수정
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={deleteNotice}
                >
                  삭제
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DetailNotice2;
