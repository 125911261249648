import Rect, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";

function SocialPrcess() {
  const location = useLocation();
  const navigate = useNavigate();
  const { accessAxios } = useAxios();

  const getNaverToken = async () => {
    if (!location.hash) return;
    const token = location.hash.split("=")[1].split("&")[0];

    try {
      const result = await accessAxios.post("/api/naver-login", {
        accessToken: token,
      });

      if (result.data.resultcode === "00") {
        const accountInfo = result.data.response;

        sessionStorage.setItem(
          "loginData",
          JSON.stringify({
            info: {
              name: accountInfo.name,
              phoneNumber: accountInfo.mobile.replace(/-/g, ""),
              gender: accountInfo.gender === "M" ? "male" : "female",
              id: accountInfo.id,
              birthDate:
                accountInfo.birthyear.substr(2, 2) +
                accountInfo.birthday.replace(/-/g, ""),
            },
            loginType: "naver",
            authority: "user",
            name: "naver",
          })
        );
        navigate("/");
      } else {
        Swal.fire({
          title: "소셜 로그인 실패",
          text: "관리자에게 문의해주세요.",
          icon: "error",
          showConfirmButton: true,
        });
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getKakaoToken = async () => {
    const queryString = location.search;

    if (!queryString.split("=")[0] === "?code") return;
    const kakaoCode = queryString.split("=")[1];
    try {
      const result = await accessAxios.post("/api/kakao-login", { kakaoCode });

      if (result.data.access_token) {
        const kakaoInfo = result.data;

        sessionStorage.setItem(
          "loginData",
          JSON.stringify({
            accessToken: kakaoInfo.access_token,
            refreshToken: kakaoInfo.refresh_token,
            loginType: "kakao",
            authority: "user",
            name: "kakao",
            info: {
              name: kakaoInfo.kakao_account.name,
              phoneNumber:
                "0" +
                kakaoInfo.kakao_account.phone_number
                  .split(" ")[1]
                  .replace(/-/g, ""),
              gender: kakaoInfo.kakao_account.gender,
              birthDate:
                kakaoInfo.kakao_account.birthyear.substr(2, 2) +
                kakaoInfo.kakao_account.birthday,
            },
          })
        );

        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNaverToken();
    getKakaoToken();
  }, []);

  return <div></div>;
}
export default SocialPrcess;
