import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReadyPage from "components/ReadyPage";
import "assets/css/Picture.css";

function Picture() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회사진</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="대회사진" />
      <ExtraBold name="대회사진" />
      <div className="container">
        <div className="row picture-ready-conter-margin">
          <div className="col text-center">
            <i className="bi bi-chat-left-dots picture-ready-icon-size"></i>
            <p className="picture-ready-font-size ">준비중입니다.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

  // return (
  //   <div>
  //     <Helmet>
  //       <title>ycf - 대회사진</title>
  //     </Helmet>
  //     <Header />
  //     <Bread first="대회정보" second="대회사진" />
  //     <ExtraBold name="대회사진" />
  //     <div className="container ">
  //       <div className="row picture-container-margin">
  //         <div className="col text-center">
  //           <Link to="#" target="_blank" className="link-font-size">
  //             <i className="bi bi-link-45deg"></i>대회사진 보러가기
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </div>
  // );
}

export default Picture;
