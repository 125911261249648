import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import IndividualInputForm from "components/IndividualInputForm";
import NotAttend from "./NotAttend";
import LimitAttend from "./LimitAttend";
import { Helmet } from "react-helmet";
import "assets/css/InputForm.css";
import "assets/css/Individual.css";
import ServerError from "./ServerError";
import { useParticipation } from "hooks/useParticipation";

function Individual() {
  const { showPage, limitPage, serverError } = useParticipation();

  if (serverError) {
    return (
      <div>
        <Helmet>
          <title>ycf - 개인참가신청</title>
        </Helmet>
        <Header />
        <div className="my-container">
          <div className="items item1">
            <Bread first="참가신청" second={"개인참가신청"} />
            <ExtraBold name="개인참가신청" />
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <ServerError />
            <div className="col"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else if (!showPage) {
    return (
      <div>
        <Helmet>
          <title>ycf - 개인참가신청</title>
        </Helmet>
        <Header />
        <div className="my-container">
          <div className="items item1">
            <Bread first="참가신청" second={"개인참가신청"} />
            <ExtraBold name="개인참가신청" />
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <NotAttend />
            <div className="col"></div>
          </div>
        </div>

        <Footer />
      </div>
    );
  } else if (!limitPage) {
    return (
      <div>
        <Helmet>
          <title>ycf - 개인참가신청</title>
        </Helmet>
        <Header />
        <div className="my-container">
          <div className="items item1">
            <Bread first="참가신청" second={"개인참가신청"} />
            <ExtraBold name="개인참가신청" />
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <LimitAttend />
            <div className="col"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>ycf - 개인참가신청</title>
      </Helmet>
      <Header />
      <div className="my-container">
        <div className="items item1">
          <Bread first="참가신청" second={"개인참가신청"} />
          <ExtraBold name="개인참가신청" />
        </div>
        <div className="items item4 sidebar-display">
          <img
            src={require("../../assets/img/sidebar_img.jpg")}
            alt="test"
            style={{ width: "250px", height: "600px", borderRadius: "1%" }}
          />
        </div>
        <div className="items item3">
          <IndividualInputForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Individual;
