import React from "react";
import "assets/css/Admin.css";
import { Link } from "react-router-dom";
import { useVerifyLogin } from "hooks/useVerifyLogin";
import "assets/css/Global.css";
import "assets/css/Admin.css";

function AdminSideBar() {
  const { handleLogOut } = useVerifyLogin();
  return (
    <div className="header-font admin-sidebar">
      <Link
        to="/admin"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span className="fs-5">여수시자전거연맹 관리자</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link to="/admin" className="nav-link text-white" aria-current="page">
            <i className="bi bi-house"></i>
            &nbsp; 대쉬보드
          </Link>
        </li>
        <li>
          <Link to="/admin-individual" className="nav-link text-white">
            <i className="bi bi-file-earmark-person"></i>&nbsp; 개인참가신청
            목록
          </Link>
        </li>
        <li>
          <Link to="/admin-group" className="nav-link text-white">
            <i className="bi bi-people"></i>&nbsp; 단체참가신청 목록
          </Link>
        </li>
        <li>
          <Link to="/admin-total-participation" className="nav-link text-white">
            <i className="bi bi-folder2-open"></i>&nbsp; 전체 참가신청 목록
          </Link>
        </li>
        <li>
          <Link to="/admin-notice2" className="nav-link text-white">
            <i className="bi bi-megaphone"></i>&nbsp; 공지사항
          </Link>
        </li>
        <li>
          <Link to="/admin-frequent" className="nav-link text-white">
            <i className="bi bi-list-ul"></i>&nbsp; 자주하는 질문
          </Link>
        </li>
        <li>
          <Link to="/admin-question2" className="nav-link text-white">
            <i className="bi bi-patch-question"></i>&nbsp; 문의사항
          </Link>
        </li>
        <li>
          <Link to="/admin-refund2" className="nav-link text-white">
            <i className="bi bi-cash-coin"></i>&nbsp; 환불신청
          </Link>
        </li>
        <li>
          <Link to="/admin-setting" className="nav-link text-white">
            <i className="bi bi-gear"></i>&nbsp; 홈페이지 설정
          </Link>
        </li>
      </ul>
      <hr />
      <div className="dropdown">
        <Link
          to="#"
          className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <strong>관리자</strong>
        </Link>
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
          <li>
            <Link className="dropdown-item" to="#"></Link>
          </li>
          <li>
            <Link
              className="dropdown-item text-white"
              to="/admin-account-setting"
            >
              계정 설정
            </Link>
          </li>
          <li>
            <Link className="dropdown-item text-white" to="/">
              홈페이지 이동
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <Link className="dropdown-item text-white" onClick={handleLogOut}>
              <i className="bi bi-door-open"></i>로그아웃
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AdminSideBar;
