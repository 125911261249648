import { useState, useEffect } from "react";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useParticipation = () => {
  const [showPage, setShowPage] = useState(true);
  const [limitPage, setLimitPage] = useState(true);
  const [serverError, setServerError] = useState(false);
  const { accessAxios } = useAxios();

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        const result = await accessAxios.post("/api/participation-period");

        if (result.data) {
          if (!ignore) {
            setShowPage(result.data.value);
          }
        }
      } catch (error) {
        console.log(error);
        if (!ignore) {
          setServerError(true);
        }

        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const fetchingLimitData = async () => {
      try {
        const result = await accessAxios.post("/api/get-total-participation");

        if (result.data) {
          if (!ignore) {
            setLimitPage(result.data.value);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchingData();
    fetchingLimitData();

    return () => {
      ignore = true;
    };
  }, []);

  return { showPage, limitPage, serverError };
};
