import React from "react";
import { Link } from "react-router-dom";
import { useVerifyLogin } from "hooks/useVerifyLogin";
import "assets/css/Admin.css";

function MobileNavbar() {
  const { handleLogOut } = useVerifyLogin();

  return (
    <div className="admin-mobile-navbar">
      <nav className="navbar navbar-expand-sm admin-mobile-navbar">
        <div className="container-fluid main-font">
          <Link className="navbar-brand text-white" to="/admin">
            여수시자전거연맹 관리자
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            style={{ backgroundColor: "white" }}
          >
            <span
              className="navbar-toggler-icon"
              style={{ backgroundColor: "white" }}
            ></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li>
                <Link
                  to="/admin"
                  className="nav-link text-white"
                  aria-current="page"
                >
                  대쉬보드
                </Link>
              </li>
              <li>
                <Link to="/admin-individual" className="nav-link text-white">
                  개인참가신청 목록
                </Link>
              </li>
              <li>
                <Link to="/admin-group" className="nav-link text-white">
                  단체참가신청 목록
                </Link>
              </li>
              <li>
                <Link
                  to="/admin-total-participation"
                  className="nav-link text-white"
                >
                  전체 참가신청 목록
                </Link>
              </li>
              <li>
                <Link to="/admin-notice2" className="nav-link text-white">
                  공지사항
                </Link>
              </li>
              <li>
                <Link to="/admin-frequent" className="nav-link text-white">
                  자주하는 질문
                </Link>
              </li>
              <li>
                <Link to="/admin-question2" className="nav-link text-white">
                  문의사항
                </Link>
              </li>
              <li>
                <Link to="/admin-refund2" className="nav-link text-white">
                  환불신청
                </Link>
              </li>
              <li>
                <Link to="/admin-setting" className="nav-link text-white">
                  홈페이지 설정
                </Link>
              </li>
            </ul>
            <hr />
            <ul className="navbar-nav text-white">
              <li>
                <Link className="text-white" to="/admin-account-setting">
                  계정 설정
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/">
                  홈페이지 이동
                </Link>
              </li>
              <li>
                <hr className="divider" />
              </li>
              <li>
                <Link className="text-white" onClick={handleLogOut}>
                  로그아웃
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default MobileNavbar;
