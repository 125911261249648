import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import ModifyLastCompetitionEditor from "components/ModifyLastCompetitionEditor";

function ModifyLastCompetition() {
  return (
    <div>
      <Helmet>
        <title>ycf - 지난대회 수정</title>
      </Helmet>
      <Header />
      <Bread first="지난대회" second={null} />
      <ExtraBold name="지난대회 수정" />
      <ModifyLastCompetitionEditor />
      <Footer />
    </div>
  );
}

export default ModifyLastCompetition;
