import React from "react";
import AdminSideBar from "components/AdminSideBar";
import MobileNavbar from "components/MobileNavbar";
import { useAdminMain } from "hooks/useAdminMain";
import { Helmet } from "react-helmet";
import { usePageSetting } from "hooks/usePageSetting";
import Spinner from "components/Spinner";
import "assets/css/Admin.css";

function Admin() {
  const { numberWithCommas, showAdminMainPageData, loading } = useAdminMain();
  const { settingValueOne } = usePageSetting();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <Helmet>
          <title>ycf관리자 - 메인보드</title>
        </Helmet>
        <AdminSideBar />
        <div className="container mt-5 ms-2 me-2">
          <div className="row pb-1">
            <div className="col-sm-8  admin-main-word admin-main-total-count">
              <p className="extar-bold-font">전체 참가자 수</p>
              <p style={{ textAlign: "right" }}>
                {numberWithCommas(Number(showAdminMainPageData.total))}명
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 admin-main-word admin-main-individual-count">
              <p className="extar-bold-font">개인 참가자 수</p>
              <p style={{ textAlign: "right" }}>
                {numberWithCommas(Number(showAdminMainPageData.individual))}명
              </p>
            </div>
            <div className="col-sm-4 admin-main-word admin-main-group-count">
              <p className="extar-bold-font">단체 참가자 수</p>
              <p style={{ textAlign: "right" }}>
                {numberWithCommas(Number(showAdminMainPageData.group))}명
              </p>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-sm-8 me-2 admin-main-word admin-main-limit-count">
              <p className="extar-bold-font">참가 가능 설정 인원 수</p>
              <p style={{ textAlign: "right" }}>
                {showAdminMainPageData.limit === 0 ? (
                  <>미설정 </>
                ) : (
                  <>{numberWithCommas(Number(showAdminMainPageData.limit))}명</>
                )}
              </p>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-sm-8 me-2 admin-main-word admin-main-attend">
              <p className="extar-bold-font">참가신청기간</p>
              <p
                className="admin-setting-text-size"
                style={{ textAlign: "right" }}
              >
                {showAdminMainPageData.startDate === 0 ||
                showAdminMainPageData.endDate === 0 ? (
                  <>미설정</>
                ) : (
                  <>
                    {showAdminMainPageData.startDate}~
                    {showAdminMainPageData.endDate}
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-sm-8 me-2 admin-main-word admin-main-competition">
              <p className="extar-bold-font">대회설정</p>
              <p
                className="admin-setting-text-size"
                style={{ textAlign: "right" }}
              >
                {settingValueOne().isBool ? (
                  <>미설정</>
                ) : (
                  <>
                    {
                      settingValueOne().isSetting[0]
                        .COMPETITION_INFORMATION_NAME
                    }
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
