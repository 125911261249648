import React from "react";

function ServerError() {
  return (
    <div className="header-font">
      <p style={{ fontSize: "48px" }}>서버 에러가 발생했습니다. </p>
      <i className="bi bi-pc-horizontal" style={{ fontSize: "100px" }}></i>
    </div>
  );
}

export default ServerError;
