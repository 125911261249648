import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useVerify } from "hooks/useVerify";
import { useAxios } from "hooks/useAxios";

export const usePageSetting = () => {
  const { accessAxios } = useAxios();
  const [startDate, setStartDate] = useState(moment()._d);
  const [endDate, setEndDate] = useState(moment().add(15, "d")._d);
  const [popupPeriod, setPopupPeriod] = useState(moment().add(15, "d")._d);
  const [render, setRender] = useState(1);
  const [competitionData, setCompetitionData] = useState([]);
  const [lastCompetitionYear, setLastCompetitionYear] = useState([]);
  const [loading, setLoading] = useState(false);

  const [settingDate, setSettingDate] = useState({
    startDate: "",
    endDate: "",
    limitParticipation: "",
    popupPeriod: "",
    originalFileName: "",
  });
  const [limitParticipation, setLimitParticipation] = useState("");
  const popupImageInputRef = useRef();
  const competitionRef = useRef();
  const lasCompetitionYearRef = useRef();
  const { verifyToken } = useVerify();

  const getStartDateValue = (date) => {
    if (moment().format("YYYYMMDD") - moment(date).format("YYYYMMDD") > 0) {
      Swal.fire({
        title: `시작날짜 선택 에러`,
        text: `시작날짜가 오늘날짜보다 빠릅니다.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      setStartDate(date);
    }
  };

  const getEndDateValue = (date) => {
    if (
      moment(startDate).format("YYYYMMDD") - moment(date).format("YYYYMMDD") >
      0
    ) {
      Swal.fire({
        title: `종료날짜 선택 에러`,
        text: `종료날짜가 시작날짜보다 빠릅니다.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      setEndDate(date);
    }
  };

  const getPopupPeriodValue = (date) => {
    if (moment().format("YYYYMMDD") - moment(date).format("YYYYMMDD") > 0) {
      Swal.fire({
        title: `종료날짜 선택 에러`,
        text: `종료날짜가 오늘날짜보다 빠릅니다.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      setPopupPeriod(date);
    }
  };

  const handleDateSetting = async () => {
    if (
      moment(startDate).format("YYYYMMDD") -
        moment(endDate).format("YYYYMMDD") >
      0
    ) {
      Swal.fire({
        title: `종료날짜 선택 에러`,
        text: `종료날짜가 시작날짜보다 빠릅니다.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/setting-create-attend", {
          startDate: moment(startDate).format("YYYY-MM-DD HH:mm:00"),
          endDate: moment(endDate).format("YYYY-MM-DD HH:mm:00"),
        });

        if (result.data.resultCode === "00") {
          setLoading(false);
          Swal.fire({
            title: `설정 완료`,
            text: `대회참가기간 설정이 되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          setRender(render + 1);
        } else {
          setLoading(false);
          Swal.fire({
            title: `설정 실패`,
            text: `관리자에게 문의해주세요.`,
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const deleteDate = async () => {
    Swal.fire({
      title: `설정기간 삭제`,
      text: `설정하신 기간을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/setting-delete-attend");

          if (result.data.resultCode === "00") {
            setLoading(false);
            setSettingDate({ ...settingDate, startDate: "", endDate: "" });
            Swal.fire({
              title: `설정 삭제`,
              text: `대회참가기간 설정이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "대회참가기간 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const getLimitParticipation = (e) => {
    console.log(e.target.value);
    setLimitParticipation(e.target.value);
  };

  const createLimitParticipation = async () => {
    if (isNaN(limitParticipation) || Number(limitParticipation) < 0) {
      Swal.fire({
        title: `숫자만 입력`,
        text: `0보다 큰 숫자만 입력해주세요.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else if (limitParticipation === "") {
      Swal.fire({
        title: `숫자 입력`,
        text: `0보다 큰 숫자를 입력해주세요.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      try {
        setLoading(true);
        const result = await accessAxios.post(
          "/api/create-limit-participation",
          {
            limit: limitParticipation,
          }
        );

        if (result.data.resultCode === "00") {
          setLoading(false);
          Swal.fire({
            title: `설정 완료`,
            text: `참가자 접수 최대인원 설정이 되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          setRender(render + 1);
        } else {
          setLoading(false);
          Swal.fire({
            title: `설정 실패`,
            text: `관리자에게 문의해주세요.`,
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const deleteLimitParticipation = () => {
    Swal.fire({
      title: `설정기간 삭제`,
      text: `최대인원 설정을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/delete-limit-participation"
          );

          if (result.data.resultCode === "00") {
            setLoading(false);
            setSettingDate({ ...settingDate, limitParticipation: "" });
            Swal.fire({
              title: `최대인원 설정 삭제`,
              text: `최대인원 설정이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "최대인원 설정 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const createPopupSetting = async () => {
    const formData = new FormData();

    formData.append("popup", popupImageInputRef.current.files[0]);
    formData.append(
      "data",
      JSON.stringify({
        popupPeriod: moment(popupPeriod).format("YYYYMMDDHHmmss"),
      })
    );

    if (!popupImageInputRef.current.files[0]) {
      Swal.fire({
        title: `파일 업로드`,
        text: `파일을 업로드해주세요.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/create-popup", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (result.data.resultCode === "00") {
          setLoading(false);
          Swal.fire({
            title: `설정 완료`,
            text: `팝업창 설정이 되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          setRender(render + 1);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "팝업 설정 실패!!!",
            text: "관리자에게 문의해주세요!!!",
            showConfirmButton: true,
          });
        }

        console.log(result.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const comfirmExcludes = (e) => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];

    const limitFileSize = 1024 * 1024 * 1;
    const file = e.target.files[0];

    if (file) {
      if (!fileAccept.includes(file.type)) {
        Swal.fire({
          title: `파일 확장자`,
          text: `해당 파일 확장자는 업로드되지 않습니다.`,
          icon: "error",
          showConfirmButton: true,
        });
        popupImageInputRef.current.value = "";
      } else if (file.size > limitFileSize) {
        Swal.fire({
          title: `파일 용량`,
          text: `파일 용량은 1MB 이하로만 업로드됩니다.`,
          icon: "error",
          showConfirmButton: true,
        });
        popupImageInputRef.current.value = "";
      }
    }
  };

  const deletePopup = () => {
    Swal.fire({
      title: `팝업 설정 삭제`,
      text: `팝업 설정을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-popup");

          if (result.data.resultCode === "00") {
            setLoading(false);
            setSettingDate({
              ...settingDate,
              popupPeriod: "",
              popupImagePath: "",
              originalFileName: "",
            });
            Swal.fire({
              title: `팝업 설정 삭제`,
              text: `팝업 설정이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "최대인원 설정 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const settingValueOne = () => {
    const isSetting = competitionData.filter(
      (item) => item.COMPETITION_IS_SETTING === 1
    );

    if (isSetting.length === 1) {
      return { isBool: false, isSetting };
    }

    return { isBool: true, isSetting };
  };

  const createCompetitionMap = () => {
    const isSetting = competitionData.filter(
      (item) => item.COMPETITION_IS_SETTING === 1
    );

    if (isSetting.length === 1) {
      return (
        <tr key={isSetting[0].COMPETITION_INFORMATION_ID}>
          <td>
            <div
              className="bold-font admin-setting-text-size"
              style={{
                border: "1px solid gray",
                borderRadius: "15px",
                marginTop: "5px",
              }}
            >
              &nbsp;&nbsp; {isSetting[0].COMPETITION_INFORMATION_NAME}
              &nbsp;&nbsp;
            </div>
          </td>
          <td>
            <button
              type="button"
              className="btn"
              style={{ padding: "0", color: "red" }}
              onClick={() => {
                unsetCompetition();
              }}
            >
              설정 해제
            </button>
          </td>
        </tr>
      );
    } else {
      return competitionData.map((data) => (
        <tr key={data.COMPETITION_INFORMATION_ID}>
          <td>{data.COMPETITION_INFORMATION_NAME}</td>
          <td>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                setCompetition(data.COMPETITION_INFORMATION_ID);
              }}
            >
              설정하기
            </button>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                deleteCompetition(data.COMPETITION_INFORMATION_ID);
              }}
            >
              삭제하기
            </button>
          </td>
        </tr>
      ));
    }
  };

  const setCompetition = async (id) => {
    try {
      const result = await accessAxios.post("/api/setting-set-competition", {
        id,
      });
      if (result.data.resultCode === "00") {
        Swal.fire({
          title: `설정 완료`,
          text: `대회 설정이 되었습니다.`,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        setRender(render + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unsetCompetition = async () => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/setting-unset-competition");

      if (result.data.resultCode === "00") {
        setLoading(false);
        Swal.fire({
          title: `설정 해제`,
          text: `대회 설정 해제되었습니다.`,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        setRender(render + 1);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const deleteCompetition = async (id) => {
    Swal.fire({
      title: `대회 삭제`,
      text: `대회를 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/setting-delete-competition",
            {
              id,
            }
          );

          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: `대회 삭제`,
              text: `대회가 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            setRender(render + 1);
          } else if (result.data.resultCode === "12") {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "참가자 존재로 삭제가 불가능합니다.",
              text: "관리자에게 문의해주세요.",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const createCompetition = async () => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/setting-create-competition", {
        name: competitionRef.current.value,
      });
      if (result.data.resultCode === "00") {
        setLoading(false);
        Swal.fire({
          title: `생성 완료`,
          text: `대회 생성되었습니다.`,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        setRender(render + 1);
        competitionRef.current.value = "";
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "대회 생성 실패",
          text: "관리자에게 문의해주세요.",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const LastCompetitionInputPressOnKeyEnter = (e) => {
    if (e.key === "Enter") {
      createLastCompetitionYear();
    }
  };

  const createLastCompetitionYear = async () => {
    const year = lasCompetitionYearRef.current.value;
    try {
      setLoading(true);
      const result = await accessAxios.post(
        "/api/create-last-competition-year",
        { year }
      );

      if (result.data.resultCode === "00") {
        setLoading(false);
        setRender(render + 1);
        Swal.fire({
          title: `연도생성 성공`,
          text: `연도생성이 되었습니다.`,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      } else if (result.data.resultCode === "13") {
        setLoading(false);
        Swal.fire({
          title: `연도중복`,
          text: `연도는 중복 될 수 없습니다.`,
          icon: "error",
          showConfirmButton: true,
        });
      }
      lasCompetitionYearRef.current.value = "";
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const createListLastCompetition = () => {
    const createMap = lastCompetitionYear.map((data) => (
      <tr key={data.LAST_COMPETITION_YEARS_ID}>
        <td>{data.LAST_COMPETITION_YEARS_ID}</td>
        <td>
          <button
            type="button"
            className="btn"
            style={{ marginLeft: "15px", padding: "0", color: "red" }}
            onClick={() => {
              deleteLastCompetition(data.LAST_COMPETITION_YEARS_ID);
            }}
          >
            삭제
          </button>
        </td>
      </tr>
    ));

    return createMap;
  };

  const deleteLastCompetition = async (year) => {
    Swal.fire({
      title: `연도 삭제`,
      text: `해당연도를 삭제하시겠습니까??`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/delete-last-competition-year",
            { year }
          );

          if (result.data.resultCode === "00") {
            setRender(render + 1);
            setLoading(false);
            Swal.fire({
              title: `연도삭제 성공`,
              text: `연도가 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (result.data.resultCode === "12") {
            setLoading(false);
            Swal.fire({
              title: `연도삭제 실패`,
              text: `해당연도에 게시물이 존재하여 삭제할 수 없습니다.`,
              icon: "error",
              showConfirmButton: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              title: `연도삭제 실패`,
              text: `연도 삭제에 실패했습니다. 관리자에게 문의해주세요.`,
              icon: "error",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/setting-read-attend");

        if (result.data) {
          if (!ignore) {
            setLoading(false);
            setSettingDate(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const competitionData = async () => {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/setting-read-competition");
        if (result.data) {
          if (!ignore) {
            setLoading(false);
            setCompetitionData(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const LastcompetitionData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-last-competition-year"
        );

        if (result.data.resultCode === "02") {
          if (!ignore) {
            setLoading(false);
            setLastCompetitionYear(result.data);
          }
        } else if (result.data.length > 0) {
          if (!ignore) {
            setLoading(false);
            setLastCompetitionYear(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    verifyToken();
    fetchingData();
    competitionData();
    LastcompetitionData();

    return () => {
      ignore = true;
    };
  }, [render]);

  useEffect(() => {}, [popupPeriod, endDate, startDate]);

  return {
    startDate,
    endDate,
    getStartDateValue,
    getEndDateValue,
    handleDateSetting,
    settingDate,
    deleteDate,
    getLimitParticipation,
    createLimitParticipation,
    deleteLimitParticipation,
    popupPeriod,
    getPopupPeriodValue,
    createPopupSetting,
    popupImageInputRef,
    deletePopup,
    comfirmExcludes,
    competitionData,
    createCompetitionMap,
    settingValueOne,
    createCompetition,
    competitionRef,
    lastCompetitionYear,
    lasCompetitionYearRef,
    createLastCompetitionYear,
    createListLastCompetition,
    LastCompetitionInputPressOnKeyEnter,
    loading,
  };
};
