import React, { useEffect } from "react";

function NaverLogin() {
  const { naver } = window;
  const naver_client_id = process.env.REACT_APP_NAVER_LOGIN_CLIEND_ID;
  const naver_callback_url = process.env.REACT_APP_NAVER_CALLBACK_URL;

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: naver_client_id,
      callbackUrl: naver_callback_url,
      isPopup: false,
      loginButton: { color: "green", type: 3, height: "47" },
    });
    naverLogin.init();
  };

  useEffect(() => {
    initializeNaverLogin();
  }, []);
  return (
    <>
      <div id="naverIdLogin"></div>
    </>
  );
}

export default NaverLogin;
