import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import "assets/css/LastCompetition.css";
import "aos/dist/aos.css";
import { useLastCompetition } from "hooks/useLastCompetition";
import Spinner from "components/Spinner";
import { useVerify } from "hooks/useVerify";

function LastCompetition() {
  const { isVerify } = useVerify();
  const {
    WriteLastCompetition,
    nowYear,
    createLi,
    loading,
    createBoard,
    list,
  } = useLastCompetition();

  if (list.length === 0) {
    return (
      <div>
        {loading ? <Spinner /> : <></>}
        <Helmet>
          <title>ycf - 지난대회</title>
        </Helmet>
        <Header />
        <Bread first="지난대회" second={null} />
        <ExtraBold name="지난대회" />
        <div className="container last-competition-space">
          {isVerify ? (
            <div className="last-competition-write-btn">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={WriteLastCompetition}
              >
                글쓰기
              </button>
            </div>
          ) : (
            <></>
          )}

          <div className="dropdown">
            <button
              className="btn btn-light dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {nowYear}
            </button>
            <ul className="dropdown-menu">{createLi()}</ul>
          </div>
          <div className="container mt-5">
            <div className="row text-center">
              <div className="col-12">
                <p>게시물이 없습니다.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 지난대회</title>
      </Helmet>
      <Header />
      <Bread first="지난대회" second={null} />
      <ExtraBold name="지난대회" />
      <div className="container">
        {isVerify ? (
          <div className="last-competition-write-btn">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={WriteLastCompetition}
            >
              글쓰기
            </button>
          </div>
        ) : (
          <></>
        )}

        <div className="dropdown">
          <button
            className="btn btn-light dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {nowYear}
          </button>
          <ul className="dropdown-menu">{createLi()}</ul>
        </div>
        <div className="card-container">{createBoard()}</div>
      </div>
      <Footer />
    </div>
  );
}

export default LastCompetition;
