import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useVerify } from "./useVerify";

export const useDetailLastCompetition = () => {
  const { accessAxios } = useAxios();
  const { isVerify } = useVerify();
  const [detailLastCompetitionData, setDetailLastCompetitionData] = useState(
    []
  );
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const moveList = () => {
    navigate(`/last-competition?year=${query.get("year")}`);
  };

  const moveModify = () => {
    navigate(
      `/modify-last-competition?year=${query.get("year")}&id=${query.get("id")}`
    );
  };

  const deleteBoard = () => {
    Swal.fire({
      title: `지난대회 게시글 삭제`,
      text: `지난대회 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/delete-board-last-competition",
            {
              year: query.get("year"),
              id: query.get("id"),
            }
          );
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `지난대회 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(`/last-competition?year=${query.get("year")}`, {
              replace: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }

        const result = await accessAxios.post(
          "/api/read-detail-last-competition",
          {
            year: query.get("year"),
            id: query.get("id"),
          }
        );
        if (!ignore) {
          setDetailLastCompetitionData(result.data);
          setLoading(false);
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };
    fetchingData();
    return () => {
      ignore = true;
    };
  }, []);

  return {
    detailLastCompetitionData,
    loading,
    isVerify,
    deleteBoard,
    moveList,
    moveModify,
  };
};
