import { useEffect, useState, useRef } from "react";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useWriteLastCompetition = () => {
  const [selectedYear, setSelectedYear] = useState("연도선택");
  const [yearsData, setYearsData] = useState([]);
  const { accessAxios } = useAxios();
  const [sumnail, setSumnail] = useState("");
  const [imagePath, setIamgePath] = useState([]);
  const [loading, setLoading] = useState(false);

  const editorRef = useRef();
  const titleRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const errSweetAlert = (text) => {
    Swal.fire({
      title: `${text} 입력창`,
      text: `${text}을 써주세요.`,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const writePageCreateLi = () => {
    if (yearsData.length > 0) {
      const createMap = yearsData.map((data) => (
        <li
          key={data.LAST_COMPETITION_YEARS_ID}
          className="dropdown-item dropdown-custom"
          onClick={() => {
            setSelectedYear(data.LAST_COMPETITION_YEARS_ID);
          }}
        >
          {data.LAST_COMPETITION_YEARS_ID}
        </li>
      ));
      return createMap;
    } else {
      return (
        <li className="dropdown-item dropdown-custom">
          생성된 연도가 없습니다.
        </li>
      );
    }
  };

  const compliteBtn = async () => {
    if (selectedYear === "연도선택") {
      Swal.fire({
        title: `연도선택`,
        text: `연도를 선택해주세요.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else if (titleRef.current.value === "") {
      errSweetAlert("제목");
    } else if (editorRef.current?.getInstance().getHTML() === "<p><br></p>") {
      errSweetAlert("내용");
    } else if (sumnail === "") {
      Swal.fire({
        title: `썸네일 파일`,
        text: `썸네일 파일을 업로드해주세요.`,
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      const formData = new FormData();
      formData.append("sumnail", sumnail);
      formData.append("year", selectedYear);
      formData.append("title", titleRef.current.value);
      formData.append("content", editorRef.current?.getInstance().getHTML());

      try {
        setLoading(true);
        const result = await accessAxios.post(
          "/api/create-board-last-competition",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLoading(false);

        if (result.data.resultCode === "00") {
          navigate(`/last-competition?year=${selectedYear}`, {
            replace: true,
          });
          Swal.fire({
            title: "글 작성완료",
            text: "지난대회 게시글이 작성되었습니다.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "글쓰기를 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const checkSumnailFile = (e) => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 1;

    const file = e.target.files[0];

    if (file) {
      if (limitVolume < file.size) {
        Swal.fire({
          title: "썸네일 허용용량 초과",
          text: "3MB 이하 이미지를 사용해주세요.",
          icon: "error",
          showConfirmButton: true,
        });
        e.target.value = "";
      } else if (!fileAccept.includes(file.type)) {
        Swal.fire({
          title: `파일 확장자`,
          text: `해당 파일 확장자는 업로드되지 않습니다.`,
          icon: "error",
          showConfirmButton: true,
        });
        e.target.value = "";
      } else {
        setSumnail(file);
      }
    }
  };

  const editorInputData = () => {
    const e = editorRef.current.getInstance().getHTML();

    sessionStorage.setItem("temporaryText", e);
  };

  const handleKeyDown = (e, ev) => {
    const content = editorRef.current.getInstance().getHTML();
    console.log(imagePath);
    if (ev.key === "Backspace" && content.includes("<img src=")) {
      const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
      const matches = content.matchAll(regex);
      const srcArr = [];

      for (const match of matches) {
        const src = match[1].split("/").pop();

        srcArr.push(src);
      }
      setIamgePath(srcArr);
    } else {
      setIamgePath([]);
    }
  };

  const handleKeyUp = async (e, ev) => {
    const content = editorRef.current.getInstance().getHTML();

    if (ev.key === "Backspace" && imagePath.length > 0) {
      const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
      const matches = content.matchAll(regex);
      const srcArr = [];

      for (const match of matches) {
        const src = match[1].split("/").pop();

        srcArr.push(src);
      }
      const missStr = [];

      for (const i of imagePath) {
        if (srcArr.indexOf(i) < 0) {
          missStr.push(i);
        }
      }

      if (missStr.length > 0) {
        accessAxios.post("/api/delete-image", {
          deleteImageList: missStr,
        });
      }
    }
  };

  const unmountedDeleteImage = () => {
    let content = sessionStorage.getItem("temporaryText");
    if (content === null) {
      content = "";
    }
    const regex = /<img[^>]*src="([^"]*)"[^>]*>/g;
    const matches = content.matchAll(regex);
    const srcArr = [];

    for (const match of matches) {
      const src = match[1].split("/").pop();

      srcArr.push(src);
    }

    if (srcArr.length > 0) {
      accessAxios.post("/api/delete-image", {
        deleteImageList: srcArr,
      });
    }
  };

  const cancelWriteLastCompetition = () => {
    if (
      titleRef.current.value === "" &&
      editorRef.current.getInstance().getHTML() === "<p><br></p>"
    ) {
      navigate("/last-competition");
    } else {
      Swal.fire({
        title: `글 작성 취소`,
        text: `글작성을 그만두고 나가시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 나가겠습니다.",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/last-competition");
        }
      });
    }
  };

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 1;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");

    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `1MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        }
      });
  }, [editorRef]);

  useEffect(() => {
    let ignore = false;
    const LastcompetitionData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post(
          "/api/read-last-competition-year"
        );

        if (result.data.resultCode === "02") {
          if (!ignore) {
            setSelectedYear("생성된 연도가 없습니다.");
            setYearsData(result.data);
            setLoading(false);
          }
          Swal.fire({
            title: "연도 생성",
            text: "관리자 페이지에서 연도를 생성해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        } else if (result.data.length > 0) {
          if (!ignore) {
            setLoading(false);
            setYearsData(result.data);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    LastcompetitionData();

    const beforeUnloadListener = (event) => {
      if (event.returnValue === "") {
        unmountedDeleteImage();
        console.log("새로고침 발생!");
      }
    };

    window.addEventListener("beforeunload", beforeUnloadListener);

    return () => {
      ignore = true;
      sessionStorage.removeItem("temporaryText");
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  useEffect(() => {
    const preventGoBack = () => {
      window.history.pushState(null, "", location.href);

      if (
        titleRef.current.value === "" &&
        editorRef.current.getInstance().getHTML() === "<p><br></p>"
      ) {
        navigate("/last-competition");
      } else {
        Swal.fire({
          title: `글 작성 취소`,
          text: `글작성을 그만두고 나가시겠습니까?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "네, 나가겠습니다..",
          cancelButtonText: "아니오",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/last-competition");
          }
        });
      }
    };

    window.history.pushState(null, "", location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => window.removeEventListener("popstate", preventGoBack);
  }, []);

  return {
    writePageCreateLi,
    selectedYear,
    titleRef,
    compliteBtn,
    editorRef,
    checkSumnailFile,
    editorInputData,
    handleKeyDown,
    handleKeyUp,
    cancelWriteLastCompetition,
    loading,
  };
};
