import React, { useState, useEffect } from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { useVerify } from "hooks/useVerify";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import Pagination2 from "components/Pagination2";
import Spinner from "components/Spinner";

function AdminNotice2() {
  const [noticeTableData, setNoticeTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWindowWidth, setIsWindowWidth] = useState(window.innerWidth < 768);
  const { isVerify } = useVerify();
  const { accessAxios } = useAxios();
  const [query, setQuery] = useSearchParams();
  const [pageNumberValue, setPageNumberValue] = useState(
    query.get("page") || 1
  );
  const [boardData, setBoardData] = useState([]);
  const navigate = useNavigate();

  const serverRequestUrl = "notice2";
  const dataParsingKey = "NOTICE";

  if (query.get("page") === null && pageNumberValue != 1) {
    setPageNumberValue(1);
  }
  if (pageNumberValue != query.get("page") && query.get("page") !== null) {
    setPageNumberValue(query.get("page"));
  }

  const truncateString = 10;

  const loopCreatePageNumber = () => {
    const totalPageNumber = boardData.totalPageNumber;

    let startNumber = Math.floor((pageNumberValue - 1) / 5);
    let endNumber = (startNumber + 1) * 5;

    if (totalPageNumber < endNumber) {
      endNumber = totalPageNumber;
    }

    const lenderingPageNumber = [];
    for (let i = startNumber * 5; i < endNumber; i++) {
      lenderingPageNumber.push(
        <Pagination.Item
          key={i}
          active={i === pageNumberValue - 1}
          onClick={() => {
            setPageNumberValue(i + 1);
            navigate(`/admin-${serverRequestUrl}?page=${i + 1}`);
          }}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    return lenderingPageNumber;
  };

  const moveFirstPage = () => {
    setPageNumberValue(1);
    navigate(`/admin-${serverRequestUrl}?page=${1}`);
  };

  const moveLastPage = () => {
    setPageNumberValue(boardData.totalPageNumber);
    navigate(`/admin-${serverRequestUrl}?page=${boardData.totalPageNumber}`);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const handleDetail = async (data) => {
    navigate(
      `/admin-detail-${serverRequestUrl}?page=${pageNumberValue}&id=${
        data[`${dataParsingKey}_ID`]
      }`
    );
  };

  const tableMap = () => {
    const createTable = noticeTableData.map((data) => (
      <tr className="row-background-color" key={data[`${dataParsingKey}_ID`]}>
        <td
          className="title-click"
          onClick={() => {
            handleDetail(data);
          }}
          style={{ textAlign: "left" }}
        >
          &nbsp;
          {isWindowWidth
            ? truncate(data[`${dataParsingKey}_TITLE`], truncateString)
            : data[`${dataParsingKey}_TITLE`]}
        </td>
        <td>{data.NOTICE_CREATED_AT}</td>
      </tr>
    ));

    return createTable;
  };

  const writeNotice = () => {
    navigate(
      `/admin-write-notice2?page=${
        query.get("page") === null ? 1 : query.get("page")
      }`
    );
  };

  useEffect(() => {
    setIsWindowWidth(window.innerWidth < 768);
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }

        let pageNum = pageNumberValue;
        if (query.get("page")) {
          pageNum = query.get("page");
        }

        const result = await accessAxios.post("/api/get-data-notice", {
          pageNumberValue: pageNum,
        });
        if (!ignore) {
          setNoticeTableData(result.data);
        }
        if (!ignore) {
          setLoading(false);
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const getCount = async () => {
      try {
        const result = await accessAxios.post("/api/get-notice-count");

        if (!ignore) {
          setBoardData(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchingData();
    getCount();

    return () => {
      ignore = true;
    };
  }, [pageNumberValue]);

  if (noticeTableData.length === 0)
    return (
      <>
        {loading ? <Spinner /> : <></>}
        <Helmet>
          <title>ycf관리자 - 공지사항</title>
        </Helmet>
        <MobileNavbar />
        <div className="d-flex">
          <AdminSideBar />
          <div className="container mt-5">
            <ExtraBold name="공지사항" />
            <div className="row text-center">
              <p>게시된 글이 없습니다.</p>
            </div>
            {isVerify ? (
              <>
                <div className="container">
                  <div className="row justify-content-end text-end">
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          writeNotice();
                        }}
                      >
                        글작성
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf관리자 - 공지사항</title>
      </Helmet>
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <div className="container mt-5">
          <ExtraBold name="공지사항" />

          <table className="table main-font" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "70%" }}>
                  제목
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  작성일
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">{tableMap()}</tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Pagination2
              pageData={{
                pageNumberValue,
                setPageNumberValue,
                loopCreatePageNumber,
                moveFirstPage,
                moveLastPage,
                boardData,
              }}
              boardType="/admin-notice2"
            />
          </div>
          {isVerify ? (
            <>
              <div className="container">
                <div className="row justify-content-end text-end">
                  <div className="col-4">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        writeNotice();
                      }}
                    >
                      글작성
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminNotice2;
