import React from "react";
import CompletPage from "components/CompletPage";
import { Helmet } from "react-helmet";

function GroupComplet() {
  return (
    <div>
      <Helmet>
        <title>ycf - 단체참가신청조회</title>
      </Helmet>
      <CompletPage moveUrl="/group-inquiry" msg="단체참가신청" />
    </div>
  );
}

export default GroupComplet;
