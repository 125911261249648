import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";

function Organization() {
  return (
    <div>
      <Helmet>
        <title>ycf - 조직소개</title>
      </Helmet>
      <Header />
      <Bread first="조직안내" second={null} />
      <ExtraBold name="조직안내" />
      <div className="container">
        <img
          src={require("assets/img/ycf_info/ycf_organization.png")}
          className="d-block w-100"
          alt="main_img"
        />
      </div>

      <Footer />
    </div>
  );
}

export default Organization;
