import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useNavigate } from "react-router-dom";

export const useVerifyLogin = () => {
  const [loginData, setLoginData] = useState({});
  const navigate = useNavigate();
  const { accessAxios } = useAxios();

  const verifyLogin = () => {
    setLoginData(JSON.parse(sessionStorage.getItem("loginData")));
  };

  const handleLogOut = async () => {
    const loginType = JSON.parse(sessionStorage.getItem("loginData")).loginType;
    try {
      if (loginType === "local") {
        const result = await accessAxios.post("/api/logout", {
          id: JSON.parse(sessionStorage.getItem("loginData")).id,
        });
        if (result.data.resultCode === "00") {
          sessionStorage.removeItem("loginData");
          setLoginData({});
          Swal.fire({
            title: "로그아웃",
            text: "로그아웃 되었습니다.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          navigate("/", { replace: true });
        } else {
          Swal.fire({
            title: "로그아웃 실패",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } else if (loginType === "naver" || loginType === "kakao") {
        sessionStorage.removeItem("loginData");
        setLoginData({});
        Swal.fire({
          title: "로그아웃",
          text: "로그아웃 되었습니다.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    verifyLogin();
  }, []);

  return { loginData, handleLogOut };
};
