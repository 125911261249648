import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Bread from "../components/Bread";
import ExtraBold from "../components/ExtraBold";
import ReadyPage from "components/ReadyPage";
import { Helmet } from "react-helmet";
import "assets/css/Limit.css";

function Record() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회기록</title>
      </Helmet>
      <Header />
      <Bread first="대회기록" second={null} />
      <ExtraBold name="대회기록" />
      <div className="container limit-margin">
        <div className="row">
          <div className="col text-center">
            <ReadyPage />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Record;
