import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";

export const useLogin = () => {
  const { accessAxios } = useAxios();
  const [user, setUser] = useState({ id: "", pw: "" });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const onChangeId = (e) => {
    setUser({ ...user, id: e.target.value });
  };

  const onChangePw = (e) => {
    setUser({ ...user, pw: e.target.value });
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/check-login", {
        ...user,
      });
      if (result.data.resultCode && result.data.resultCode === "00") {
        sessionStorage.setItem("loginData", JSON.stringify(result.data.data));
        setLoading(false);
        Swal.fire({
          title: "안녕하세요.",
          text: "반갑습니다. 관리자님",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/", { replace: true });
      } else if (result.data.resultCode === "35") {
        setLoading(false);
        Swal.fire({
          title: "로그인 실패",
          text: "아이디 or 비밀번호가 다릅니다.",
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "로그인 실패",
          text: "계속 안되면 관리자에게 문의주세요.",
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("loginData")) {
      sessionStorage.removeItem("loginData");
    }
  }, []);

  return {
    setUser,
    onChangeId,
    onChangePw,
    handleLogin,
    handleOnKeyPress,
    loading,
  };
};
