import React from "react";
import "../assets/css/Global.css";
import "../assets/css/Upload.css";
import { CloudDownload } from "react-bootstrap-icons";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

function Download(props) {
  const { accessAxios } = useAxios();
  const handleDownload = async () => {
    try {
      const result = await accessAxios.post(props.url, null, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([result.data], { type: result.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", props.downloadFileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  return (
    <div className="mt-3 header-font">
      <div className="container header-font"></div>
      <div
        style={{
          border: "1px black solid",
          display: "inline",
          borderRadius: "15px",
          padding: "5px 10px 5px 10px",
          cursor: "pointer",
        }}
      >
        <a onClick={handleDownload}>{props.buttonName}</a>
        &nbsp;
        <CloudDownload size={20} />
      </div>
    </div>
  );
}

export default Download;
