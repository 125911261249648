import React from "react";

import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";

function TextEdiorModify(props) {
  const colorSyntaxOptions = {
    preset: [
      "#333333",
      "#666666",
      "#FFFFFF",
      "#EE2323",
      "#F89009",
      "#009A87",
      "#006DD7",
      "#8A3DB6",
      "#781B33",
      "#5733B1",
      "#953B34",
      "#FFC1C8",
      "#FFC9AF",
      "#9FEEC3",
      "#99CEFA",
      "#C1BEF9",
    ],
  };
  const toolbarItemsOptions = [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["image", "link"],
    ["code", "codeblock"],
  ];
  return (
    <>
      <div className="inquiry-input mb-3">
        <label htmlFor="title" className="col-sm-2 col-form-label bold-font">
          제목
        </label>
        <input
          ref={props.sendData.titleRef}
          type="text"
          id="title"
          className="form-control input-max-width"
          defaultValue={props.sendData.modifyData.title}
        />
      </div>
      <Editor
        previewStyle="vertical"
        height="400px"
        initialEditType="wysiwyg"
        hideModeSwitch={true}
        toolbarItems={toolbarItemsOptions}
        plugins={[[colorSyntax, colorSyntaxOptions]]}
        ref={props.sendData.editorRef}
      />
    </>
  );
}

export default TextEdiorModify;
