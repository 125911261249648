import React, { useState, useEffect, useRef } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";

import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

function WriteRefund2() {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const titleRef = useRef();
  const editorRef = useRef();
  const writerRef = useRef();
  const pwRef = useRef();
  const { accessAxios } = useAxios();
  const defaultText =
    "<p><strong>참가신청 유형</strong> : 개인참가신청 / 단체참가신청</p><p><strong>이름 </strong>:&nbsp;</p><p><strong>연락처</strong> :&nbsp;</p><p><strong>환불받을 은행</strong> :&nbsp;</p><p><strong>환불받을 계좌번호</strong> :&nbsp;</p><p><br></p>";

  const colorSyntaxOptions = {
    preset: [
      "#333333",
      "#666666",
      "#FFFFFF",
      "#EE2323",
      "#F89009",
      "#009A87",
      "#006DD7",
      "#8A3DB6",
      "#781B33",
      "#5733B1",
      "#953B34",
      "#FFC1C8",
      "#FFC9AF",
      "#9FEEC3",
      "#99CEFA",
      "#C1BEF9",
    ],
  };
  const toolbarItemsOptions = [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["image", "link"],
    ["code", "codeblock"],
  ];

  const errSweetAlert = (text) => {
    Swal.fire({
      title: `${text} 입력창`,
      text: `${text}을 써주세요.`,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const writeBoard = async () => {
    let pass = false;
    let writeBoard;

    writeBoard = {
      title: titleRef.current.value,
      writer: writerRef.current.value,
      pw: pwRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
    };

    if (writeBoard.title === "") {
      errSweetAlert("제목");
    } else if (writeBoard.writer === "") {
      errSweetAlert("작성자");
    } else if (writeBoard.pw === "") {
      errSweetAlert("비밀번호");
    } else if (
      writeBoard.content ===
      "<p><strong>참가신청 유형</strong> : 개인참가신청 / 단체참가신청</p><p><strong>이름 </strong>:&nbsp;</p><p><strong>연락처</strong> :&nbsp;</p><p><strong>환불받을 은행</strong> :&nbsp;</p><p><strong>환불받을 계좌번호</strong> :&nbsp;</p><p><br></p>"
    ) {
      errSweetAlert("내용");
    } else {
      pass = true;
    }

    if (pass) {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/write-refund", {
          ...writeBoard,
        });

        if (result.data.resultCode === "00") {
          setLoading(false);
          Swal.fire({
            title: `환불신청 글 작성완료`,
            text: `환불신청 게시글이 작성되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          navigate("/refund2", { replace: true });
        } else {
          setLoading(false);
          Swal.fire({
            title: "글쓰기를 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  const cancelBoard = () => {
    if (
      titleRef.current.value === "" &&
      editorRef.current.getInstance().getHTML() === defaultText &&
      writerRef.current.value === "" &&
      pwRef.current.value === ""
    ) {
      navigate(
        `/refund2?page=${query.get("page") === null ? 1 : query.get("page")}`
      );
    } else {
      Swal.fire({
        title: `글 작성 취소`,
        text: `글작성을 그만두고 나가시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 나가겠습니다..",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/refund2?page=${
              query.get("page") === null ? 1 : query.get("page")
            }`
          );
        }
      });
    }
  };

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    const limitVolume = 1024 * 1024 * 3;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `3MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        }
      });
  }, []);
  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 환불신청 글쓰기</title>
      </Helmet>
      <Header />
      <Bread first="환불신청" second={null} />
      <ExtraBold name="환불신청 글쓰기" />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="mb-1">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label bold-font"
              >
                제목 <span className="essential">*</span>
              </label>
              <input
                ref={titleRef}
                type="text"
                id="title"
                className="form-control input-max-width"
                maxLength={100}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="questionWriter" className="form-label bold-font">
              작성자 <span className="essential">*</span>
            </label>
            <input
              ref={writerRef}
              type="text"
              className="form-control"
              id="questionWriter"
              maxLength={10}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="questionPw" className="form-label bold-font">
              비밀번호 <span className="essential">*</span>
            </label>
            <input
              ref={pwRef}
              type="password"
              className="form-control"
              id="questionPw"
              autoComplete="false"
              maxLength={10}
            />
          </div>
        </div>

        <div className="row mt-3">
          <Editor
            previewStyle="vertical"
            height="400px"
            initialEditType="wysiwyg"
            hideModeSwitch={true}
            toolbarItems={toolbarItemsOptions}
            plugins={[[colorSyntax, colorSyntaxOptions]]}
            ref={editorRef}
            initialValue={defaultText}
          />
        </div>
        <div className="row justify-content-end">
          <div className="col-8 text-end">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={writeBoard}
            >
              작성완료
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={cancelBoard}
            >
              취소
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WriteRefund2;
