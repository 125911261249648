import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import "assets/css/Salutation.css";

function Salutation() {
  return (
    <div>
      <Helmet>
        <title>ycf - 인사말</title>
      </Helmet>
      <Header />
      <Bread first="인사말" second={null} />
      <ExtraBold name="인사말" />

      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col ">
            <img
              src={require("assets/img/ycf_info/salutation.png")}
              alt="main_img"
              className="salutation-img-size"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Salutation;
