import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bread from "../../components/Bread";
import ExtraBold from "../../components/ExtraBold";
import FrequentModify from "components/FrequentModify";
import { useFrequentModify } from "hooks/useFrequentModify";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";

function ModifyFrequent() {
  const {
    frequentData,
    titleRef,
    loading,
    editorRef,
    modifingData,
    cancelModify,
  } = useFrequentModify();

  return (
    <>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 자주하는 질문 수정</title>
      </Helmet>
      <Header />
      <Bread first="자주하는 질문" second={null} />
      <ExtraBold name="자주하는 질문 수정" />
      <FrequentModify
        fn={{ frequentData, titleRef, editorRef, modifingData, cancelModify }}
      />
      <Footer />
    </>
  );
}

export default ModifyFrequent;
