import { useState, useEffect } from "react";
import moment from "moment";

export const usePop = (props) => {
  const [popup, setPopup] = useState(false);

  const handleOverflowunset = () => {
    setPopup(true);
    document.body.style.overflow = "unset";
  };

  const handleTodayNoShow = () => {
    localStorage.setItem(`popup`, moment().format("YYYYMMDD235959"));
    setPopup(true);
  };

  useEffect(() => {
    let ignore = false;
    const localStoragePopSettingValue = localStorage.getItem(`popup`);
    const nowTime = moment().format("YYYYMMDDHHmmss");

    if (localStoragePopSettingValue !== null) {
      if (localStoragePopSettingValue < nowTime) {
        localStorage.removeItem(`popup`);
      } else {
        if (!ignore) {
          setPopup(true);
        }
      }
    }

    if (popup === false) {
      document.body.style.overflow = "hidden;";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [popup]);

  return { popup, handleTodayNoShow, handleOverflowunset };
};
