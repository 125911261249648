import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bread from "../../components/Bread";
import ExtraBold from "../../components/ExtraBold";

import Sponsor from "components/Sponsor";
import { Helmet } from "react-helmet";

function SponsorPage() {
  return (
    <div>
      <Helmet>
        <title>ycf - 후원사</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="후원사" />
      <ExtraBold name="후원사" />
      <Sponsor />
      <Footer />
    </div>
  );
}

export default SponsorPage;
