import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import Bold from "components/Bold";
import Download from "components/Download";
import { Helmet } from "react-helmet";
import "assets/css/Outline.css";

function Info() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회안내</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second={"대회안내"} />
      <ExtraBold name="대회안내" />

      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_1.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_2.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_3.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_4.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_5.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_6.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_7.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_8.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/2024_sumsum_yeosu_granfondo_outline/outline_9.jpeg")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row" style={{ height: "20vh" }}>
          <div className="col">
            <Bold name="단체접수서식" />
            <Download
              url={`/api/format-excel-file`}
              downloadFileName={"2024년_여수_그란폰도_단체접수_서식.xlsx"}
              buttonName={"단체접수 엑셀파일 서식 다운로드"}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Info;
