import { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useAxios } from "hooks/useAxios";

export const useFrequentModify = () => {
  const [frequentData, setFrequentData] = useState({
    title: "",
    content: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const titleRef = useRef();
  const editorRef = useRef();
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);

  const cancelModify = () => {
    navigate("/frequent", { replace: true });
  };

  const modifingData = async () => {
    const modifedData = {
      title: titleRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
      id: location.state.id,
    };

    try {
      const result = await accessAxios.post("/api/modify-frequent", {
        ...modifedData,
      });

      if (result.data.resultCode === "00") {
        Swal.fire({
          title: "수정완료",
          text: "자주하는 질문 수정되었습니다..",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/frequent", { replace: true });
      } else {
        Swal.fire({
          icon: "error",
          title: "수정하기 실패",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    editorRef.current.getInstance().setHTML(frequentData.content);
  });

  useEffect(() => {
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        const formData = new FormData();

        formData.append("image", blob);

        try {
          const result = await accessAxios.post("/api/image-upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          const imagePath = result.data.fileName;

          callback(process.env.REACT_APP_IMAGE_SERVER_URL + imagePath, "image");
        } catch (error) {
          console.log(error);

          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      });
  }, [editorRef]);

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-where-frequent", {
          id: location.state.id,
        });

        if (Object.keys(result.data[0]).length > 0) {
          if (!ignore) {
            setLoading(false);
            setFrequentData({
              ...frequentData,
              title: result.data[0]?.FREQUENT_TITLE,
              content: result.data[0]?.FREQUENT_CONTENT,
            });
          }
        } else {
          if (!ignore) {
            setLoading(false);
            setFrequentData({ title: "데이터 불러오기 실패" });
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
      }
    };
    fetchingData();

    return () => {
      ignore = true;
    };
  }, []);

  return {
    frequentData,
    loading,
    titleRef,
    editorRef,
    cancelModify,
    modifingData,
  };
};
