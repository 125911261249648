import React from "react";
import classnames from "classnames";
import "assets/css/Popup.css";
import { usePop } from "hooks/usePop";
import { useMain } from "hooks/useMain";

function MyPopUp(props) {
  const { popup, handleTodayNoShow, handleOverflowunset } = usePop(props);
  const { popupData } = useMain();

  return (
    <div className={classnames({ test: !popup, "test-none": popup })}>
      <div className="pop-container">
        <div>
          <button
            type="button"
            className="btn btn-outline-light today-button"
            onClick={handleTodayNoShow}
          >
            오늘 하루 안보기
          </button>
          <button
            type="button"
            className="btn btn-outline-light close-button"
            onClick={handleOverflowunset}
          >
            닫기
          </button>
        </div>
        <div>
          <img
            className="pop-img"
            src={
              process.env.REACT_APP_POPUP_IMAGE_SERVER_URL +
              popupData.popupImagePath
            }
            alt="image"
          />
        </div>
      </div>
    </div>
  );
}

export default MyPopUp;
