import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { useInquiry } from "hooks/useInquiry";
import classnames from "classnames";
import "assets/css/Inquiry.css";
import { Helmet } from "react-helmet";

function GroupInquiry() {
  const {
    getData,
    participationApplicationInquiry,
    isValid,
    nameRef,
    phoneNumberRef,
  } = useInquiry("group");

  return (
    <div>
      <Helmet>
        <title>ycf - 단체참가신청조회</title>
      </Helmet>
      <Header />
      <Bread first="참가신청" second={"단체참가신청조회"} />
      <ExtraBold name="단체참가신청조회" />
      <div className="container inquiry-margin">
        <div className="row">
          <form>
            <div className="mt-5">
              <div className="inquiry-input">
                <label
                  htmlFor="name"
                  className="col-sm-2 col-form-label bold-font"
                >
                  대표자 이름
                </label>
                <input
                  type="text"
                  id="name"
                  className={classnames("form-control input-max-width", {
                    "is-invalid": isValid.name,
                  })}
                  onChange={getData}
                  maxLength={20}
                  ref={nameRef}
                />
              </div>
              <div className="inquiry-input mt-2">
                <label
                  htmlFor="phoneNumber"
                  className="col-sm-2 col-form-label bold-font"
                >
                  대표자 전화번호
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  className={classnames("form-control input-max-width", {
                    "is-invalid": isValid.phoneNumber,
                  })}
                  placeholder="(-)제외하고 입력"
                  onChange={getData}
                  maxLength={11}
                  ref={phoneNumberRef}
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-6 mt-5 text-center">
                  <button
                    type="butoon"
                    className="inquiry-btn"
                    onClick={participationApplicationInquiry}
                  >
                    단체참가신청 조회하기
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GroupInquiry;
