import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";
import { useQuestion } from "hooks/useQuestion";
import Pagination2 from "components/Pagination2";

function Question2() {
  const {
    tableMap,
    setPageNumberValue,
    pageNumberValue,
    loopCreatePageNumber,
    moveFirstPage,
    moveLastPage,
    boardData,
    loading,
    writeQuestion,
    questionTableData,
  } = useQuestion();

  if (questionTableData.length === 0)
    return (
      <>
        <Helmet>
          <title>ycf - 문의사항</title>
        </Helmet>
        {loading ? <Spinner /> : <></>}
        <Header />
        <Bread first="문의사항" second={null} />
        <ExtraBold name="문의사항" />
        <div className="container">
          <div className="container text-center">
            <p>게시된 글이 없습니다.</p>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-end text-end">
            <div className="col-4">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  writeQuestion();
                }}
              >
                글작성
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );

  return (
    <div>
      <div>
        <Helmet>
          <title>ycf - 문의사항</title>
        </Helmet>
        {loading ? <Spinner /> : <></>}
        <Header />
        <Bread first="문의사항" second={null} />
        <ExtraBold name="문의사항" />
        <div className="container">
          <table className="table main-font" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "50%" }}>
                  제목
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  작성자
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  작성일
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">{tableMap()}</tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Pagination2
              pageData={{
                pageNumberValue,
                setPageNumberValue,
                loopCreatePageNumber,
                moveFirstPage,
                moveLastPage,
                boardData,
              }}
            />
          </div>

          <div className="container">
            <div className="row justify-content-end text-end">
              <div className="col-4">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    writeQuestion();
                  }}
                >
                  글작성
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Question2;
