import React from "react";
import LoginPage from "components/LoginPage";
import { useLogin } from "hooks/useLogin";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";

function Login() {
  const {
    setUser,
    onChangeId,
    onChangePw,
    handleLogin,
    handleOnKeyPress,
    loading,
  } = useLogin();

  return (
    <>
      <Helmet>
        <title>로그인</title>
      </Helmet>
      {loading ? <Spinner /> : <></>}
      <LoginPage
        fn={{
          setUser,
          onChangeId,
          onChangePw,
          handleLogin,
          handleOnKeyPress,
        }}
      />
    </>
  );
}

export default Login;
