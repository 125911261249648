import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import TextEditorModify from "components/TextEdiorModify";
import Spinner from "components/Spinner";
import { useModifyNotice } from "hooks/useModifyNotice";

function ModifyNotice2() {
  const {
    modifyData,
    modifingData,
    editorRef,
    titleRef,
    cancelModify,
    loading,
  } = useModifyNotice();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 공지사항 수정</title>
      </Helmet>
      <Header />
      <Bread first="공지사항" second={null} />
      <ExtraBold name="공지사항 글쓰기수정" />
      <div className="container">
        <div className="row">
          <TextEditorModify
            sendData={{
              modifyData,
              modifingData,
              editorRef,
              titleRef,
            }}
          />
        </div>
        <div className="row justify-content-end mt-2">
          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={modifingData}
            >
              수정완료
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={cancelModify}
            >
              취소
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ModifyNotice2;
