import React, { useRef } from "react";
import FrequentTextEditor from "components/FrequentTextEditor";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bread from "../../components/Bread";
import ExtraBold from "../../components/ExtraBold";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useAxios } from "hooks/useAxios";

function WriteFrequent() {
  const navigate = useNavigate();

  const titleRef = useRef();
  const editorRef = useRef();
  const { accessAxios } = useAxios();

  const errSweetAlert = (text) => {
    Swal.fire({
      title: `${text} 입력창`,
      text: `${text}을 써주세요.`,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const writeBoard = async () => {
    let pass = false;
    let writeBoard;

    writeBoard = {
      title: titleRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
    };

    if (writeBoard.title === "") {
      errSweetAlert("제목");
    } else if (writeBoard.content === "<p><br></p>") {
      errSweetAlert("내용");
    } else {
      pass = true;
    }

    if (pass) {
      try {
        const result = await accessAxios.post("/api/write-frequent", {
          ...writeBoard,
        });

        if (result.data.resultCode === "00") {
          Swal.fire({
            title: `자주하는 질문 글 작성완료`,
            text: `자주하는 질문이 작성되었습니다.`,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          navigate("/frequent", { replace: true });
        } else {
          Swal.fire({
            title: "글쓰기를 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const cancelBoard = () => {
    if (
      titleRef.current.value === "" &&
      editorRef.current.getInstance().getHTML() === "<p><br></p>"
    ) {
      navigate("/frequent");
    } else {
      Swal.fire({
        title: `글 작성 취소`,
        text: `글작성을 그만두고 나가시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 나가겠습니다..",
        cancelButtonText: "아니오",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/frequent", { replace: true });
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>ycf - 자주하는 질문 글쓰기</title>
      </Helmet>
      <Header />
      <Bread first="자주하는 질문" second={null} />
      <ExtraBold name="자주하는 질문 글쓰기" />
      <div className="container">
        <div className="row">
          <FrequentTextEditor
            fn={{ writeBoard, cancelBoard, titleRef, editorRef }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WriteFrequent;
