import React from "react";
import "assets/css/Limit.css";

function NotAttend() {
  return (
    <div className="header-font limit-margin">
      <i className="bi bi-send-x limit-icon-size"></i>
      <p className="limit-attend-font-size">
        지금은 신청기간이 아닙니다 &#58;&#41;
      </p>
    </div>
  );
}

export default NotAttend;
