import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import LastCompetitionEditor from "components/LastCompetitionEditor";

function WriteLastCompetition() {
  return (
    <div>
      <Helmet>
        <title>ycf - 지난대회 글쓰기</title>
      </Helmet>
      <Header />
      <Bread first="지난대회" second={null} />
      <ExtraBold name="지난대회 글쓰기" />
      <LastCompetitionEditor />
      <Footer />
    </div>
  );
}

export default WriteLastCompetition;
