import React from "react";

import "assets/css/InquiryTable.css";
import "assets/css/Complet.css";
import Spinner from "components/Spinner";
import { useInquiryComplet } from "hooks/useInquiryComplet";
import { Helmet } from "react-helmet";

function IndividualInquiryComplet() {
  const { handleMoveInquiry, handleMoveHome, inquiryData, loading } =
    useInquiryComplet("individual");

  if (inquiryData?.noData) {
    return (
      <div className="container text-center mt-5">
        <Helmet>
          <title>ycf - 개인참가신청조회</title>
        </Helmet>
        <div className="row">
          <div className="col">
            <p className="individual-com">개인참가신청조회</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <b style={{ fontSize: "25px" }}>일치하는 참가자가 없습니다.</b>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6">
            <button
              className="btn-size btn-inquiry"
              onClick={handleMoveInquiry}
            >
              개인참가신청 조회 페이지 이동
            </button>
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-lg-6">
            <button className="btn-size btn-home" onClick={handleMoveHome}>
              홈 이동
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 개인참가신청조회</title>
      </Helmet>
      <div
        className="container tex<Helmet>
        <title>ycf - 개인참가신청조회</title>
      </Helmet>t-center mt-5"
      >
        <div className="row">
          <div className="col">
            <p className="individual-com">개인참가신청조회</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <table className="table my-table table-nowrap">
            <tbody>
              <tr>
                <th scope="row" className="first-column">
                  이름
                </th>
                <td className="second-column">{inquiryData[0]?.name}</td>
              </tr>
              <tr>
                <th scope="row" className="first-column">
                  전화번호
                </th>
                <td>{inquiryData[0]?.phoneNumber}</td>
              </tr>
              <tr>
                <th scope="row" className="first-column">
                  생년월일
                </th>
                <td>{inquiryData[0]?.birthDate}</td>
              </tr>
              <tr>
                <th scope="row" className="first-column">
                  성별
                </th>
                <td>{inquiryData[0]?.gender}</td>
              </tr>
              <tr>
                <th scope="row" className="first-column">
                  코스종류
                </th>
                <td>{inquiryData[0]?.course}</td>
              </tr>
              <tr>
                <th scope="row" className="first-column">
                  구분
                </th>
                <td>{inquiryData[0]?.cycle}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-lg-6">
          <button className="btn-size btn-inquiry" onClick={handleMoveInquiry}>
            개인참가신청 조회 페이지 이동
          </button>
        </div>
      </div>
      <div className="row justify-content-center mt-1">
        <div className="col-lg-6">
          <button className="btn-size btn-home" onClick={handleMoveHome}>
            홈 이동
          </button>
        </div>
      </div>
    </div>
  );
}

export default IndividualInquiryComplet;
