import React from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { useAccountSetting } from "hooks/useAccountSetting";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import Spinner from "components/Spinner";
import "assets/css/Admin.css";

function AdminAccountSetting() {
  const {
    inputPassword1Ref,
    inputPassword2Ref,
    inputPassword3Ref,
    modifyPassword,
    loading,
  } = useAccountSetting();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <Helmet>
          <title>ycf관리자 - 계정 설정</title>
        </Helmet>
        <AdminSideBar />
        <div className="container mt-5">
          <ExtraBold name="계정 설정" />
          <div className="row">
            <div className="col">
              <label htmlFor="inputPassword1" className="form-label main-font">
                기존 비밀번호
              </label>
              <input
                type="password"
                id="inputPassword1"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                style={{ width: "350px" }}
                ref={inputPassword1Ref}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <label htmlFor="inputPassword2" className="form-label main-font">
                변경 비밀번호 입력
              </label>
              <input
                type="password"
                id="inputPassword2"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                style={{ width: "350px" }}
                ref={inputPassword2Ref}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="inputPassword3" className="form-label main-font">
                변경 비밀번호 확인
              </label>
              <input
                type="password"
                id="inputPassword3"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                style={{ width: "350px" }}
                ref={inputPassword3Ref}
              />
            </div>
          </div>
          <div className="row mt-1" style={{ width: "362px" }}>
            <div className="col text-end" style={{ paddingRight: "0px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={modifyPassword}
              >
                비밀번호 변경
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAccountSetting;
