import React from "react";
import AdminSideBar from "components/AdminSideBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale/ko";
import moment from "moment";
import ExtraBold from "components/ExtraBold";
import { usePageSetting } from "hooks/usePageSetting";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import "assets/css/Admin.css";
import Spinner from "components/Spinner";

function AdminSetting() {
  const {
    startDate,
    endDate,
    getStartDateValue,
    getEndDateValue,
    handleDateSetting,
    settingDate,
    deleteDate,
    getLimitParticipation,
    createLimitParticipation,
    deleteLimitParticipation,
    popupPeriod,
    getPopupPeriodValue,
    createPopupSetting,
    popupImageInputRef,
    deletePopup,
    comfirmExcludes,
    createCompetitionMap,
    settingValueOne,
    createCompetition,
    competitionRef,
    lastCompetitionYear,
    lasCompetitionYearRef,
    createLastCompetitionYear,
    createListLastCompetition,
    LastCompetitionInputPressOnKeyEnter,
    loading,
  } = usePageSetting();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 홈페이지 설정</title>
        </Helmet>
        <div className="container-fluid mt-5 admin-setting-container">
          <ExtraBold name="홈페이지 설정" />
          <div className="row">
            <div className="col extar-bold-font">대회참가신청기간 설정</div>
            <div className="border-bottom"></div>
          </div>
          {(settingDate?.startDate === "" && settingDate?.endDate === "") ||
          (settingDate?.startDate === null && settingDate?.endDate === null) ? (
            <>
              <div className="row mt-3">
                <div className="col d-flex ">
                  <span className="bold-font align-self-center">
                    시작&nbsp;
                  </span>
                  <DatePicker
                    className="form-control"
                    locale={ko}
                    selected={startDate}
                    onChange={getStartDateValue}
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="시작시간"
                  />
                  <p className="align-self-center" style={{ margin: "0" }}>
                    &nbsp;~&nbsp;
                  </p>
                  <span className="bold-font align-self-center">
                    종료&nbsp;
                  </span>
                  <DatePicker
                    className="form-control"
                    locale={ko}
                    selected={endDate}
                    onChange={getEndDateValue}
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="종료시간"
                  />
                  &nbsp;
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={handleDateSetting}
                    >
                      설정하기
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="row mt-1">
            <div className="col-sm-12">
              {moment(settingDate?.startDate).format("YYYY-MM-DD HH:mm:ss") !==
                "Invalid date" &&
              moment(settingDate?.endDate).format("YYYY-MM-DD HH:mm:ss") !==
                "Invalid date" ? (
                <>
                  <div
                    className="bold-font admin-table admin-setting-text-size"
                    style={{
                      border: "1px solid gray",
                      borderRadius: "15px",
                      display: "inline",
                    }}
                  >
                    &nbsp;&nbsp;
                    <span className="">
                      {moment(settingDate?.startDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                    <span> ~ </span>
                    <span>
                      {moment(settingDate?.endDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn"
                    style={{ padding: "0", color: "red" }}
                    onClick={deleteDate}
                  >
                    설정 삭제
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="border-bottom mt-2"></div>
          </div>

          <div className="row mt-5">
            <div className="col extar-bold-font">
              참가자 접수 최대 인원 설정
            </div>
            <div className="border-bottom mt-1 mb-1"></div>
          </div>
          <div className="row">
            <div className="d-flex">
              {settingDate?.limitParticipation === "" ||
              settingDate?.limitParticipation === null ? (
                <>
                  <input
                    type="tel"
                    className="form-control"
                    style={{ width: "150px" }}
                    onChange={getLimitParticipation}
                    maxLength={5}
                  />
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={createLimitParticipation}
                    style={{ width: "90px" }}
                  >
                    설정하기
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              {settingDate?.limitParticipation === "" ||
              settingDate?.limitParticipation === null ? (
                <></>
              ) : (
                <>
                  <div
                    className="bold-font"
                    style={{
                      border: "1px solid gray",
                      borderRadius: "15px",
                      display: "inline",
                    }}
                  >
                    &nbsp;&nbsp;
                    <span>{settingDate.limitParticipation}명</span>
                    &nbsp;&nbsp;
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn"
                    style={{ padding: "0", color: "red" }}
                    onClick={deleteLimitParticipation}
                  >
                    설정 삭제
                  </button>
                </>
              )}
            </div>
            <div className="border-bottom"></div>
          </div>
          <div className="row mt-5">
            <div className="col extar-bold-font">팝업창 설정</div>
            <div className="border-bottom mt-1 mb-1"></div>
          </div>
          {settingDate?.popupPeriod === "" &&
          settingDate?.popupImagePath === "" &&
          settingDate?.originalFileName === "" ? (
            <>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="formFileSm"
                      className="form-label main-font"
                    >
                      팝업 업로드 이미지
                    </label>
                    <input
                      className="form-control form-control-sm"
                      id="formFileSm"
                      type="file"
                      ref={popupImageInputRef}
                      accept=".jpeg, .png, .bmp, .gif, .webp, .svg"
                      onChange={comfirmExcludes}
                    />
                    <span className="main-font" style={{ fontSize: "12px" }}>
                      용량 1MB이하,업로드 가능 확장자 : .jpg .png .bmp .gif
                      .webp .svg
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col d-flex ">
                  <span className="bold-font align-self-center">
                    종료일자&nbsp;
                  </span>
                  <DatePicker
                    className="form-control"
                    locale={ko}
                    selected={popupPeriod}
                    onChange={getPopupPeriodValue}
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="종료시간"
                  />
                  &nbsp;
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={createPopupSetting}
                    >
                      설정하기
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col">
                  <div
                    className="bold-font admin-setting-text-size"
                    style={{
                      border: "1px solid gray",
                      borderRadius: "15px",
                    }}
                  >
                    &nbsp;&nbsp;
                    <span>업로드 파일명 : {settingDate?.originalFileName}</span>
                    &nbsp;&nbsp;
                  </div>
                  <div
                    className="bold-font admin-setting-text-size"
                    style={{
                      border: "1px solid gray",
                      borderRadius: "15px",
                      marginTop: "5px",
                    }}
                  >
                    &nbsp;&nbsp;
                    <span>
                      팝업창 종료일자 :
                      {moment(
                        settingDate?.popupPeriod,
                        "YYYYMMDDHHmmss"
                      ).format("YYYY-MM-DD HH:mm")}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn"
                    style={{ padding: "0", color: "red" }}
                    onClick={deletePopup}
                  >
                    설정 삭제
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="border-bottom mt-2"></div>
          <div className="row mt-5">
            <div className="col extar-bold-font">대회 설정</div>
            <div className="border-bottom mt-1 mb-1"></div>
          </div>
          {settingValueOne().isBool ? (
            <div className="row">
              <div className="col-sm-3 d-flex">
                <input
                  type="text"
                  className="form-control"
                  ref={competitionRef}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-1"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    createCompetition();
                  }}
                >
                  생성하기
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row mt-1">
            <div className="col">
              <table>
                <tbody>{createCompetitionMap()}</tbody>
              </table>
            </div>
          </div>
          <div className="border-bottom mt-2"></div>
          <div className="row mt-5">
            <div className="col extar-bold-font">지난대회 연도관리</div>
            <div className="border-bottom mt-1 mb-1"></div>
          </div>
          <div className="row">
            <div className="col-sm-3 d-flex">
              <input
                type="text"
                className="form-control"
                ref={lasCompetitionYearRef}
                onKeyDown={LastCompetitionInputPressOnKeyEnter}
              />
              <button
                type="button"
                className="btn btn-outline-primary ms-1"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => {
                  createLastCompetitionYear();
                }}
              >
                생성하기
              </button>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col">
              {lastCompetitionYear.length > 0 ? (
                <table>
                  <tbody>{createListLastCompetition()}</tbody>
                </table>
              ) : (
                <span>생성된 연도가 없습니다.</span>
              )}
            </div>
          </div>
          <div className="mb-5"></div>
        </div>
      </div>
    </div>
  );
}

export default AdminSetting;
