import { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import Pagination from "react-bootstrap/Pagination";

export const useListPaging = (serverUrl, props) => {
  const [boardData, setBoardData] = useState([]);
  const { accessAxios } = useAxios();

  const loopCreatePageNumber = () => {
    const totalPageNumber = boardData.totalPageNumber;

    let startNumber = Math.floor((props.fn.pageNumberValue - 1) / 10);
    let endNumber = (startNumber + 1) * 10;

    if (totalPageNumber < endNumber) {
      endNumber = totalPageNumber;
    }

    const lenderingPageNumber = [];
    for (let i = startNumber * 10; i < endNumber; i++) {
      lenderingPageNumber.push(
        <Pagination.Item
          key={i}
          active={i === props.fn.pageNumberValue - 1}
          onClick={() => {
            props.fn.setPageNumberValue(i + 1);
          }}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    return lenderingPageNumber;
  };

  const moveFirstPage = () => {
    props.fn.setPageNumberValue(1);
  };
  const moveLastPage = () => {
    props.fn.setPageNumberValue(boardData.totalPageNumber);
  };

  useEffect(() => {
    let ignore = false;
    const getCount = async () => {
      try {
        const result = await accessAxios.post("/api" + serverUrl);

        if (!ignore) {
          setBoardData(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCount();

    return () => {
      ignore = true;
    };
  }, [serverUrl]);

  return {
    boardData,
    loopCreatePageNumber,
    moveFirstPage,
    moveLastPage,
  };
};
