import React, { useState, useEffect } from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useVerify } from "hooks/useVerify";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import { useAxios } from "hooks/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";
import "assets/css/DetailBoard.css";

function AdminDetailNotice2() {
  const [detailNoticeData, setDetailNoticeData] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { isVerify } = useVerify();

  useEffect(() => {
    let igonre = false;
    const fetchingData = async () => {
      const id = query.get("id");
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-detail-notice", {
          id,
        });
        setDetailNoticeData(result.data);
        if (!igonre) {
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();
    return () => {
      igonre = true;
    };
  }, []);

  const moveList = () => {
    navigate(
      `/admin-notice2?page=${
        query.get("page") === null ? 1 : query.get("page")
      }`
    );
  };

  const moveModify = () => {
    navigate(
      `/admin-modify-notice2?page=${query.get("page")}&id=${query.get("id")}`
    );
  };

  const deleteNotice = () => {
    Swal.fire({
      title: `공지사항 게시글 삭제`,
      text: `공지사항 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-notice", {
            id: query.get("id"),
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `공지사항 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(`/admin-notice2?page=${query.get("page")}`);
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <Helmet>
        <title>ycf관리자 - 공지사항 상세보기</title>
      </Helmet>
      <div className="d-flex">
        <AdminSideBar />

        <div className="container mt-5">
          <ExtraBold name="공지사항" />
          <div className="row extar-bold-font detail-board-title">
            <div className="col">{detailNoticeData[0]?.NOTICE_TITLE}</div>
          </div>
          <div className="row text-end bold-font detail-writer">
            <div className="col">
              {detailNoticeData[0]?.NOTICE_WRITER}
              &nbsp;&nbsp;
              {detailNoticeData[0]?.NOTICE_CREATED_AT}
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col img-size-adjust"
              dangerouslySetInnerHTML={{
                __html: detailNoticeData[0]?.NOTICE_CONTENT,
              }}
            ></div>
          </div>
          <br />
          <div className="row justify-content-end text-end bold-font">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={moveList}
              >
                목록
              </button>
              {isVerify ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary me-2"
                    onClick={moveModify}
                  >
                    수정
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={deleteNotice}
                  >
                    삭제
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3"></div>
    </div>
  );
}

export default AdminDetailNotice2;
