import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const verifyToken = () => {
    if (sessionStorage.getItem("loginData")) {
      if (JSON.parse(sessionStorage.getItem("loginData")).access_token)
        return true;
    }
    return false;
  };
  return verifyToken() ? <Navigate to="/" /> : <Outlet />;
}

export default ProtectedRoute;
