import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { history } from "history.js";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useInquiryComplet = (type) => {
  const [inquiryData, setInquiryData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { accessAxios } = useAxios();

  const handleMoveInquiry = () => {
    if (type === "individual") {
      navigate("/individual-inquiry", { replace: true });
    } else if (type === "group") {
      navigate("/group-inquiry", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const unlistenHistoryEvent = history.listen(({ action }) => {
    if (type === "individual" || "group") {
      if (action === "POP") {
        handleMoveInquiry();
      }
    }
  });

  const handleMoveHome = () => {
    navigate("/", { replace: true });
  };

  const tableMap = () => {
    const result = inquiryData.map((data, index) => (
      <tr className="header-font" key={data.id}>
        <td scope="row">{index + 1}</td>
        <td>
          {data.isLeader === "대표" ? (
            <span
              style={{
                background: "#36bdef",
                width: "10px",
                height: "10px",
                borderRadius: " 10px",
                display: "inline-block",
              }}
            ></span>
          ) : (
            <></>
          )}
          {data.name}
        </td>
        <td>{data.phoneNumber}</td>
        <td>{data.birthDate}</td>
        <td>{data.gender}</td>
        <td>{data.course}</td>
        <td>{data.cycle}</td>
      </tr>
    ));

    return result;
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      let requestAddress;

      if (type === "individual") {
        requestAddress = "/individual-inquiry";
      } else if (type === "group") {
        requestAddress = "/group-inquiry";
      }
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api" + requestAddress, {
          name: location.state.name,
          phoneNumber: location.state.phoneNumber,
        });

        if (result.data?.resultCode === "02") {
          if (!ignore) {
            setLoading(false);
            setInquiryData({ noData: "noData" });
          }
        } else {
          if (!ignore) {
            setLoading(false);
            setInquiryData(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        Swal.fire({
          icon: "error",
          title: "단체참가신청 조회 실패",
          text: "관리자에게 문의해주세요!!!",
        });

        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {}, [unlistenHistoryEvent]);

  return { handleMoveInquiry, handleMoveHome, inquiryData, tableMap, loading };
};
