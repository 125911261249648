import React from "react";
import "../assets/css/Bold.css";

function Bold(props) {
  return (
    <div className="mt-5">
      <div className="container">
        <div className="row">
          <h2 className="header-font">{props.name}</h2>
        </div>
      </div>
    </div>
  );
}

export default Bold;
