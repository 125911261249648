import { useState, useRef } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";

export const useAccountSetting = () => {
  const inputPassword1Ref = useRef();
  const inputPassword2Ref = useRef();
  const inputPassword3Ref = useRef();
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);

  const modifyPassword = async () => {
    if (inputPassword2Ref.current.value !== inputPassword2Ref.current.value) {
      Swal.fire({
        title: "새로 입력한 비밀번호가 다릅니다.",
        text: "변경 비밀번호와 확인 비밀번호가 다릅니다.",
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      try {
        setLoading(true);
        const existingPasswordResult = await accessAxios.post(
          "/api/check-existing-password",
          {
            id: JSON.parse(sessionStorage.getItem("loginData")).id,
            existingPassword: inputPassword1Ref.current.value,
          }
        );

        if (existingPasswordResult.data.resultCode === "00") {
          const changePasswordResult = await accessAxios.post(
            "/api/change-password",
            {
              id: JSON.parse(sessionStorage.getItem("loginData")).id,
              changePassword: inputPassword3Ref.current.value,
            }
          );

          if (changePasswordResult.data.resultCode === "00") {
            Swal.fire({
              title: "비밀번호 변경 완료",
              text: "비밀번호가 변경되었습니다.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            inputPassword1Ref.current.value = "";
            inputPassword2Ref.current.value = "";
            inputPassword3Ref.current.value = "";
          }
        } else {
          Swal.fire({
            title: "기존 비밀번호가 다릅니다.",
            text: "기존 비밀번호를 다시 확인해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    }
  };

  return {
    inputPassword1Ref,
    inputPassword2Ref,
    inputPassword3Ref,
    modifyPassword,
    loading,
  };
};
