import React from "react";
import { useInquiryComplet } from "hooks/useInquiryComplet";
import { Helmet } from "react-helmet";
import Spinner from "components/Spinner";
import "assets/css/Global.css";
import "assets/css/Inquiry.css";

function GroupInquiryComplet() {
  const { handleMoveInquiry, handleMoveHome, inquiryData, tableMap, loading } =
    useInquiryComplet("group");

  if (inquiryData?.noData) {
    return (
      <div className="container text-center mt-5">
        <Helmet>
          <title>ycf - 단체참가신청조회</title>
        </Helmet>
        <div className="row">
          <div className="col">
            <p className="individual-com">단체참가신청조회</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <b style={{ fontSize: "25px" }}>일치하는 참가자가 없습니다.</b>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6">
            <button
              type="button"
              className="btn-size btn-inquiry"
              onClick={handleMoveInquiry}
            >
              단체참가신청 조회 페이지 이동
            </button>
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-lg-6">
            <button
              type="button"
              className="btn-size btn-home"
              onClick={handleMoveHome}
            >
              홈 이동
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 단체참가신청조회</title>
      </Helmet>
      <div className="container text-center mt-5">
        <div className="row">
          <div className="col">
            <p className="individual-com">단체참가신청조회</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col header-font">
            {inquiryData[0]?.teamName ? (
              <>단체명 : {inquiryData[0]?.teamName} </>
            ) : (
              <></>
            )}
          </div>
          <div className="col">
            <span
              style={{
                background: "#36bdef",
                width: "10px",
                height: "10px",
                borderRadius: " 10px",
                display: "inline-block",
              }}
            ></span>
            <span> : 대표</span>
          </div>
        </div>
        <div className="row">
          <table className="table my-table table-nowrap">
            <tbody>
              <tr className="table-header-color">
                <th scope="col">No.</th>
                <th scope="col">이름</th>
                <th scope="col">전화번호</th>
                <th scope="col">생년월일</th>
                <th scope="col">성별</th>
                <th scope="col">코스종류</th>
                <th scope="col">구분</th>
              </tr>
              {tableMap()}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-lg-6">
          <button className="btn-size btn-inquiry" onClick={handleMoveInquiry}>
            단체참가신청 조회 페이지 이동
          </button>
        </div>
      </div>
      <div className="row justify-content-center mt-1">
        <div className="col-lg-6">
          <button
            type="button"
            className="btn-size btn-home"
            onClick={handleMoveHome}
          >
            홈 이동
          </button>
        </div>
      </div>
    </div>
  );
}

export default GroupInquiryComplet;
