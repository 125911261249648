import React from "react";

function Accordion(props) {
  return (
    <div>
      <div className="accordion" id="accordionExample">
        {props.fn.accordionMap(props.fn.frequentData)}
      </div>
    </div>
  );
}

export default Accordion;
