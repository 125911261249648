import React from "react";
import "assets/css/Limit.css";

function LimitAttend() {
  return (
    <div className="header-font limit-margin">
      <i className="bi bi-send-x limit-icon-size"></i>
      <p className="limit-attend-font-size">
        신청인원이 마감되었습니다 &#58;&#41;
      </p>
    </div>
  );
}

export default LimitAttend;
