import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bread from "../../components/Bread";
import ExtraBold from "../../components/ExtraBold";
import Bold from "../../components/Bold";
import { Helmet } from "react-helmet";

function Award() {
  return (
    <div>
      <Helmet>
        <title>ycf - 시상안내</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="시상안내" />
      <ExtraBold name="시상안내" />
      <Bold name="KOM 시상안내" />
      <div className="container">
        <div className="row mt-5">
          <p className="bold-font">남자 로드 KOM</p>
          <p>- KOM져지, 상품</p>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/male.jpeg")}
              alt="infor_image"
            />
          </div>
        </div>
        <div className="row mt-5">
          <p className="bold-font">여자 로드 KOM</p>
          <p>- KOM져지, 상품</p>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/female.jpeg")}
              alt="infor_image"
            />
          </div>
        </div>
        <div className="row mt-5">
          <p className="bold-font">남자 MTB KOM</p>
          <p>- KOM져지, 상품</p>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/male.jpeg")}
              alt="infor_image"
            />
          </div>
        </div>
        <div className="row mt-5">
          <p className="bold-font">여자 MTB KOM</p>
          <p>- KOM져지, 상품</p>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/female.jpeg")}
              alt="infor_image"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Award;
