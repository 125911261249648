import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import MyPopUp from "components/MyPopUp";
import { useMain } from "hooks/useMain";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import CarouselsReact from "components/CarouselsReact";
import MainNotice from "components/MainNotice";
import "assets/css/Popup.css";
import "assets/css/Kakao.css";

function Main() {
  const { showPopup, loading, createMainPageNoticeTable } = useMain();

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>여수시 자전거 연맹</title>
      </Helmet>
      <Header />
      <CarouselsReact />
      {showPopup ? <MyPopUp /> : <></>}
      <div className="mt-4"></div>
      <MainNotice tableData={createMainPageNoticeTable} />
      <div className="kakao-link">
        <Link to="http://pf.kakao.com/_GxoAtG/chat" target="_blank">
          <img
            src={require("assets/img/kakao_link.png")}
            className="kakao-img"
            alt="kakao link"
          />
          <p className="kakao-text">문의하기</p>
          <p className="kakao-text">09:00~17:00</p>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
