import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";

function Pagination2(props) {
  const navigate = useNavigate();

  return (
    <div>
      <Pagination>
        <Pagination.First
          onClick={() => {
            props.pageData.moveFirstPage();
          }}
        />
        <Pagination.Prev
          onClick={() => {
            if (props.pageData.pageNumberValue > 1) {
              props.pageData.setPageNumberValue(
                props.pageData.pageNumberValue - 1
              );
              navigate(
                `${props.boardType}?page=${props.pageData.pageNumberValue - 1}`
              );
            }
          }}
        />
        {props.pageData.loopCreatePageNumber()}
        <Pagination.Next
          onClick={() => {
            if (
              props.pageData.pageNumberValue <
              props.pageData.boardData.totalPageNumber
            ) {
              props.pageData.setPageNumberValue(
                props.pageData.pageNumberValue + 1
              );
              navigate(
                `${props.boardType}?page=${props.pageData.pageNumberValue + 1}`
              );
            }
          }}
        />
        <Pagination.Last
          onClick={() => {
            props.pageData.moveLastPage();
          }}
        />
      </Pagination>
    </div>
  );
}

export default Pagination2;
