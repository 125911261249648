import React from "react";
import { Link } from "react-router-dom";
import "assets/css/MainPageNotice.css";

function MainNotice(props) {
  return (
    <div>
      <div
        className="container main-notice-container"
        style={{ height: "250px" }}
      >
        <div className="row">
          <div className="col-md-6 sm-main-notice">
            <div className="notice-and-see-more main-font">
              <div style={{ fontWeight: "bold" }}>공지사항</div>
              <div>
                <Link className="see-more" to="/notice2">
                  더보기 <i className="bi bi-three-dots-vertical see-more"></i>
                </Link>
              </div>
            </div>
            <div className="col">
              <table
                className="table main-font admin-table"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "80%" }}>
                      제목
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                      작성일
                    </th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {props.tableData()}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div className="individual-group-button-container">
              <Link to="/individual">
                <div>
                  <div className="icon-circle1">
                    <i className="bi bi-person-fill individual icon-default1"></i>
                    <p className="icon-text1 bold-font">개인참가신청</p>
                  </div>
                </div>
              </Link>
              <Link to="/group">
                <div className="icon-circle1">
                  <i className="bi bi-people-fill group icon-default1"></i>
                  <p className="icon-text1 bold-font">단체참가신청</p>
                </div>
              </Link>
              <Link to="/individual-inquiry">
                <div className="icon-circle1">
                  <i className="bi bi-check2 group icon-default1"></i>
                  <p className="icon-text1 bold-font">개인참가신청조회</p>
                </div>
              </Link>
              <Link to="/group-inquiry">
                <div className="icon-circle1">
                  <i className="bi bi-check2-all group icon-default1"></i>
                  <p className="icon-text1 bold-font">단체참가신청조회</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainNotice;
