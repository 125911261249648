import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Bread from "../../components/Bread";
import ExtraBold from "../../components/ExtraBold";
import Bold from "../../components/Bold";
import { Helmet } from "react-helmet";
import "assets/css/Souvenir.css";

function Souvenir() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회기념품</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="대회기념품" />
      <ExtraBold name="대회기념품" />
      <Bold name="참가자 경품" />
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/cycle.jpeg")}
              alt="infor_image"
            />
          </div>
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/frame.jpeg")}
              alt="infor_image"
            />
          </div>
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/wheel.jpeg")}
              alt="infor_image"
            />
          </div>
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/tire.jpeg")}
              alt="infor_image"
            />
          </div>
        </div>

        <Bold name="참가자 기념품" />
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/all.png")}
              alt="infor_image"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <img
              className="souvenir-img-size"
              src={require("assets/img/2024_sumsum_souvenir/medal.png")}
              alt="infor_image"
            />
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row picture-ready-conter-margin">
          <div className="col text-center">
            <i className="bi bi-chat-left-dots picture-ready-icon-size"></i>
            <p className="picture-ready-font-size ">준비중입니다.</p>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default Souvenir;
