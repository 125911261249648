import React from "react";
import "../assets/css/Info.css";

function ExtraBold(props) {
  return (
    <div>
      <div className="container mb-2">
        <div className="row">
          <h1 className="h1-color header-font">{props.name}</h1>
        </div>
      </div>
    </div>
  );
}

export default ExtraBold;
