import React from "react";
import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import Spinner from "./Spinner";
import { useWriteLastCompetition } from "hooks/useWriteLastCompetition";

function LastCompetitionEditor() {
  const {
    writePageCreateLi,
    selectedYear,
    titleRef,
    editorRef,
    compliteBtn,
    checkSumnailFile,
    editorInputData,
    handleKeyDown,
    handleKeyUp,
    cancelWriteLastCompetition,
    loading,
  } = useWriteLastCompetition();

  const colorSyntaxOptions = {
    preset: [
      "#333333",
      "#666666",
      "#FFFFFF",
      "#EE2323",
      "#F89009",
      "#009A87",
      "#006DD7",
      "#8A3DB6",
      "#781B33",
      "#5733B1",
      "#953B34",
      "#FFC1C8",
      "#FFC9AF",
      "#9FEEC3",
      "#99CEFA",
      "#C1BEF9",
    ],
  };
  const toolbarItemsOptions = [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["image", "link"],
    ["code", "codeblock"],
    ["scrollSync"],
  ];

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <div className="container">
        <div className="row bold-font">
          <div className="col">
            <div className="dropdown" style={{ display: "inline" }}>
              <button
                className="btn btn-light dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ paddingLeft: "0" }}
              >
                {selectedYear}
              </button>
              <span className="essential">*</span>
              <ul className="dropdown-menu">{writePageCreateLi()}</ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-1">
            <label
              htmlFor="title"
              className="col-sm-2 col-form-label bold-font"
            >
              제목 <span className="essential">*</span>
            </label>
            <input
              type="text"
              id="title"
              className="form-control input-max-width mb-2"
              maxLength={100}
              ref={titleRef}
            />
            <Editor
              previewStyle="vertical"
              height="400px"
              initialEditType="wysiwyg"
              hideModeSwitch={true}
              toolbarItems={toolbarItemsOptions}
              plugins={[[colorSyntax, colorSyntaxOptions]]}
              ref={editorRef}
              onChange={editorInputData}
              onKeydown={handleKeyDown}
              onKeyup={handleKeyUp}
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              썸네일 이미지 업로드 <span className="essential">*</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              accept=".jpeg, .png, .bmp, .gif, .webp, .svg"
              onChange={checkSumnailFile}
            />
            <span className="main-font" style={{ fontSize: "12px" }}>
              용량 1MB 이하, 업로드 가능 확장자 : .jpg .png .bmp .gif .webp .svg
            </span>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-md-3 text-end">
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={compliteBtn}
            >
              작성완료
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={cancelWriteLastCompetition}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastCompetitionEditor;
