import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";

function YcfInfo() {
  return (
    <div>
      <Helmet>
        <title>ycf - 인사말</title>
      </Helmet>
      <Header />
      <Bread first="연맹소개" second={null} />
      <ExtraBold name="연맹소개" />
      <div className="container">
        <div className="row ms-5">
          <div
            dangerouslySetInnerHTML={{
              __html: `<br/><br/><h3 class="bold-font">설립목적 및 지위</h3><p><br></p><p>- 여수시 자전거연맹은 자전거를 시민에게 널리 보급하여 시민체력을 향상케 하며, 건전한 여가선용과 명랑한 기풍을 진작하는 한편 운동선수 및 그 단체를 지원⋅육성하고 우수한 선수를 양성하여 국위선양에 이바지함을 목적으로 한다.</p><p>- 본 연맹은 자전거를 소관 하는 도 종목단체 등에 대하여 독점적 교섭권을 갖는 자전거의 유일한 단체로서 여수시를 대표한다.</p><p><br></p><h3 class="bold-font">사업 및 기능</h3><p><br></p><p>- 자전거에 관한 기본방침의 심의․결정</p><p>- 자전거대회에 관한 자문 및 건의 </p><p>- 자전거에 관한 국제대회․전국대회의 개최 및 참가</p><p>- 자전거의 산하연맹체의 지도와 지원 </p><p>- 자전거 대회의 주최 및 주관 </p><p>- 자전거 경기기술의 연구 및 향상  </p><p>- 자전거의 선수 및 심판, 운영요원 등의 양성</p><p>- 자전거의 경기시설․장비의 개발․개량 및 이와 관련된 공인  </p><p>- 자전거의 동호인 조직 및 스포츠클럽 육성 지원</p><p>- 자전거의 생활체육프로그램 개발․보급 </p><p>- 자전거단체의 사업수행에 필요한 재원조달을 위한 수익사업</p><p>- 그 밖의 자전거진흥 및 본연맹의 목적달성에 필요한 사업</p>`,
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default YcfInfo;
